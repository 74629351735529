import HistoryMove from './history-move';
import { useGetHistoryMoves } from '@/modules/Room/Hooks/useGetHistoryMoves';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowIcon from 'assets/arrow-down.svg?react';
import { MouseEvent, useEffect } from 'react';

interface HistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentRound?: number;
}

export const HistoryModal = ({
  isOpen,
  onClose,
  currentRound,
}: HistoryModalProps) => {
  const gameHistory = useGetHistoryMoves(!!currentRound);
  const { activeRoomId } = useParams();
  const { t } = useTranslation();
  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 z-[100] bg-black bg-opacity-75 w-full h-full flex items-center justify-center"
      onClick={handleClose}
    >
      <div className="bg-grape relative m-[20px] rounded-[12px] w-full max-w-[1088px] lg:border-[3px] border-dark-blue overflow-hidden flex flex-col rounded-t-[8px] lg:rounded-t-[16px]">
        <div className="flex w-full rounded-t-[8px] lg:rounded-t-[16px] py-[2px] uppercase text-[8px] lg:text-[14px] text-gray font-light bg-[#202066]">
          <span className="w-[33%] flex justify-center">Player 1</span>
          <span className="w-[34%] flex justify-center">
            {t('common.round')}
          </span>
          <span className="w-[33%] flex justify-center">Player 2</span>
        </div>
        <div className={`h-[320px] lg:max-h-[470px] overflow-auto history`}>
          {activeRoomId &&
            gameHistory.map((round, index) => (
              <HistoryMove
                key={round.encrMoveA + index}
                round={round}
                gameCount={gameHistory.length - index}
                show={
                  currentRound
                    ? gameHistory.length - index <= currentRound
                    : undefined
                }
              />
            ))}
        </div>
        <div
          className="flex justify-center items-center gap-[8px] text-[#FFFFFF99] font-normal text-[12px] lg:text-[20px] leading-[24px] p-[4px] lg:p-[12px] cursor-pointer mt-[6px] lg:mt-[0] bg-[#202066] rounded-[12px] lg:rounded-[16px] border-[1px] border-[#FFFFFF26] m-[4px]"
          onClick={onClose}
        >
          Hide <ArrowIcon className="w-[12px] h-[12px]" />
        </div>
      </div>
    </div>
  );
};
