import React, { useEffect, useRef } from 'react';
import LookingOpponentModal from './modals/LookingOpponentModal/LookingOpponentModal';
import NoOneJoinModal from './modals/NoOneJoinModal/NoOneJoinModal';
import { useRoomStore } from 'shared/store/roomStore';
import { useModalStore } from '../../store/modalStore';
import { ModalTypeEnum } from '../../constants/modalEnums';
// import GameBetModal from './modals/GameBetModal/GameBetModal';
import NotEnoughBalanceModal from './modals/NotEnoughBalanceModal/NotEnoughBalanceModal';
import { Timer } from 'shared/constants/types';
import { useTimersStore } from 'shared/store/timersStore';
import { NO_PLAYER_FOUND } from 'shared/constants/timers';
import CreateNewGameModal from './modals/CreateNewGameModal/CreateNewGameModal';
import ConnectingToPlayerModal from './modals/ConnectingToPlayerModal/ConnectingToPlayerModal';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { NULL_ADDRESS } from 'shared/constants/constants';
import ConnectingToOpponentLoaderModal from './modals/ConnectingToOpponentLoaderModal/ConnectingToOpponentLoaderModal';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useBalance } from 'shared/hooks/useBalance';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import TransactionTakeToLongModal from 'modules/Room/RoomModals/components/ModalsPlayGame/modals/TransactionTakeToLongModal/TransactionTakeToLongModal';
import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';

const ModalsConnectCreate = () => {
  const timerRef = useRef<Timer | null>(null);
  const navigate = useNavigate();
  const { roomBalance, roomStatus, roomsDataIsFetching, playerB, playerA } =
    useRoomStore();
  const { joinHash, startHash } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const {
    closeAllModals,
    modalState,
    setNotEnoughModalContinue,
    setNotEnoughModalZeroBalance,
    setModalState,
  } = useModalStore();
  const { loaderStartTime, setLoaderStartTime } = useTimersStore();
  const { selectedCurrency } = useCurrenciesStore();
  const { balance } = useBalance({
    currencyAddress: selectedCurrency?.address ?? undefined,
    walletAddress: address as `0x${string}`,
  });
  const { setNotFirstTime, notFirstTime } = useGlobalStore();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  useEffect(() => {
    if (
      selectedCurrency &&
      typeof balance === 'number' &&
      +selectedCurrency.bet?.[0] > balance! &&
      !notFirstTime &&
      !activeRoomId
    ) {
      closeAllModals();
      setModalState(ModalTypeEnum.NOT_ENOUGH_COINS, true);
      setNotEnoughModalContinue(true);
      setNotEnoughModalZeroBalance(balance === 0);
      setNotFirstTime(true);
    }
  }, [selectedCurrency, balance]);
  useEffect(() => {
    if (
      roomBalance === 0n &&
      !activeRoomId &&
      !modalState[ModalTypeEnum.CREATE_NEW_ROOM] &&
      !modalState[ModalTypeEnum.CONNECT_TO_PLAYER] &&
      !modalState[ModalTypeEnum.NOT_ENOUGH_COINS] &&
      !startHash[chainId!]?.hash &&
      !joinHash[chainId!]?.hash
    ) {
      closeAllModals();
      // setModalState(ModalTypeEnum.SET_BET, true);
      navigate(ROUTES.LOBBY);
    } else if (startHash[chainId!]?.hash) {
      closeAllModals();
      setModalState(ModalTypeEnum.CREATE_NEW_ROOM, true);
    } else if (joinHash[chainId!]?.hash) {
      closeAllModals();
      setModalState(ModalTypeEnum.CONNECTING_TO_PLAYER, true);
    }
  }, [roomBalance, activeRoomId, startHash, joinHash]);

  useEffect(() => {
    if (
      activeRoomId &&
      ![
        RoomStatusEnum.ReadyForGame,
        RoomStatusEnum.ReadyForReveal,
        RoomStatusEnum.Closed,
      ].includes(roomStatus) &&
      !roomsDataIsFetching &&
      playerB?.address === NULL_ADDRESS &&
      playerA?.address === address?.toLowerCase()
    ) {
      if (!loaderStartTime?.[activeRoomId + address + chainId]) {
        setLoaderStartTime(activeRoomId + address + chainId, new Date());
        closeAllModals();
        setModalState(ModalTypeEnum.LOOKING_OPPONENT, true);
        timerRef.current = setTimeout(() => {
          setModalState(ModalTypeEnum.NO_ONE_JOIN, true);
          if (isMobile) setModalState(ModalTypeEnum.LOOKING_OPPONENT, false);
        }, NO_PLAYER_FOUND);
      } else {
        const currentTime = new Date();
        const value = loaderStartTime?.[activeRoomId! + address + chainId];
        const date = value ? new Date(value) : null;
        let diff;
        if (date) diff = currentTime.getTime() - date.getTime();
        if (diff && diff > NO_PLAYER_FOUND) {
          closeAllModals();
          setModalState(ModalTypeEnum.LOOKING_OPPONENT, !isMobile);
          setModalState(ModalTypeEnum.NO_ONE_JOIN, true);
        } else if (diff) {
          closeAllModals();
          setModalState(ModalTypeEnum.LOOKING_OPPONENT, true);
          timerRef.current = setTimeout(() => {
            setModalState(ModalTypeEnum.NO_ONE_JOIN, true);
            if (isMobile) setModalState(ModalTypeEnum.LOOKING_OPPONENT, false);
          }, NO_PLAYER_FOUND - diff);
        }
      }
    }

    if (
      [
        RoomStatusEnum.ReadyForGame,
        RoomStatusEnum.ReadyForReveal,
        RoomStatusEnum.Closed,
      ].includes(roomStatus) &&
      loaderStartTime?.[activeRoomId! + address + chainId]
    ) {
      setLoaderStartTime(activeRoomId! + address + chainId, null);
    }
    return () => clearTimeout(timerRef.current as Timer);
  }, [
    selectedCurrency,
    balance,
    activeRoomId,
    roomStatus,
    roomsDataIsFetching,
    isMobile,
  ]);

  return (
    <>
      <AnimatedTransition show={modalState[ModalTypeEnum.NOT_ENOUGH_COINS]}>
        <NotEnoughBalanceModal
          open={modalState[ModalTypeEnum.NOT_ENOUGH_COINS]}
        />
      </AnimatedTransition>
      <AnimatedTransition show={modalState[ModalTypeEnum.CREATE_NEW_ROOM]}>
        <CreateNewGameModal open={modalState[ModalTypeEnum.CREATE_NEW_ROOM]} />
      </AnimatedTransition>
      <AnimatedTransition show={modalState[ModalTypeEnum.CONNECT_TO_PLAYER]}>
        <ConnectingToPlayerModal
          open={modalState[ModalTypeEnum.CONNECT_TO_PLAYER]}
        />
      </AnimatedTransition>
      <AnimatedTransition show={modalState[ModalTypeEnum.LOOKING_OPPONENT]}>
        <LookingOpponentModal />
      </AnimatedTransition>
      <AnimatedTransition show={modalState[ModalTypeEnum.NO_ONE_JOIN]}>
        <NoOneJoinModal open={modalState[ModalTypeEnum.NO_ONE_JOIN]} />
      </AnimatedTransition>
      <AnimatedTransition show={modalState[ModalTypeEnum.CONNECTING_TO_PLAYER]}>
        <ConnectingToOpponentLoaderModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.TRANSACTION_TAKE_TO_LONG]}
      >
        <TransactionTakeToLongModal
          open={modalState[ModalTypeEnum.TRANSACTION_TAKE_TO_LONG]}
        />
      </AnimatedTransition>
    </>
  );
};
export default ModalsConnectCreate;



