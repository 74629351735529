import React, { useEffect, useRef, useState } from 'react';
import { Timer } from 'shared/constants/types';
import { TIME_BEFORE_SHOW_TIMER_IN_MODALS } from 'shared/constants/timers';
import { epochToLocalUTC } from 'shared/utils/time';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import Modal from 'shared/components/ui/Modal/Modal';
import DigitalClock from 'shared/components/ui/DigitalClock/DigitalClock';
import { useTimersStore, State, Actions } from 'shared/store/timersStore';
import { useRoomStore } from 'shared/store/roomStore';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { useCloseUnusedRoom } from 'modules/Room/RoomModals/hooks/useCloseUnusedRoom';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
  modalName: string;
  setShowTimer: (value: boolean) => void;
  setShowCloseButton: (value: boolean) => void;
  setTechnicalDefeatError: (value: boolean) => void;
  selector: string;
}

const WithTimerLogicModalHOC = ({
  children,
  modalName,
  setShowTimer: setShowTimerParent,
  setShowCloseButton: setShowCloseButtonParent,
  setTechnicalDefeatError,
  selector,
}: Props) => {
  const { closerRoom, roomStatus, roundCounter, setRoomExpire } =
    useRoomStore();
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const timeOutRef = useRef<null | Timer>(null);
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const onExpire = () => {
    setShowTimer(false);
    setShowTimerParent(false);
    setShowCloseButton(true);
    setShowCloseButtonParent(true);
    setRoomExpire(true);
  };
  const timersStore = useTimersStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const storedTimerKey = `${modalName}ShowTimer` as keyof State;
  const setStoredTimerKey = `set${modalName
    .charAt(0)
    .toUpperCase()}${modalName.slice(1)}ShowTimer` as keyof Actions;

  useEffect(() => {
    if (
      !timersStore[storedTimerKey]?.[
        activeRoomId! + address + chainId + roundCounter
      ] &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      if (new Date() >= epochToLocalUTC(closerRoom?.deadline)) {
        setShowTimer(true);
        setShowTimerParent(true);
      } else {
        timersStore[setStoredTimerKey](
          activeRoomId! + address + chainId + roundCounter,
          new Date(new Date().getTime() + TIME_BEFORE_SHOW_TIMER_IN_MODALS)
        );
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
          setShowTimerParent(true);
        }, TIME_BEFORE_SHOW_TIMER_IN_MODALS);
      }
    } else if (roomStatus !== RoomStatusEnum.Closed) {
      const currentTime = new Date();
      const storedTimerValue =
        timersStore[storedTimerKey]?.[
          activeRoomId! + address + chainId + roundCounter
        ];
      const targetTime = storedTimerValue
        ? new Date(storedTimerValue)
        : new Date();
      const diffInMilliseconds = targetTime.getTime() - currentTime.getTime();
      if (diffInMilliseconds >= TIME_BEFORE_SHOW_TIMER_IN_MODALS) {
        setShowTimer(true);
        setShowTimerParent(true);
      } else {
        const timeRemaining = targetTime.getTime() - currentTime.getTime();
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
          setShowTimerParent(true);
        }, timeRemaining);
      }
    }
    return () => {
      clearTimeout(timeOutRef.current as Timer);
    };
  }, [setShowTimerParent]);

  const { closeUnusedRoomHandler, isSuccess, technicalDefeatError } =
    useCloseUnusedRoom();
  useEffect(() => {
    if (technicalDefeatError) setTechnicalDefeatError(technicalDefeatError);
  }, [technicalDefeatError, setTechnicalDefeatError]);
  const hostElement = document.querySelector(selector);
  if (!hostElement) return null;
  return createPortal(
    <Modal classNames="left-[3%] w-[94%] sm:m-[14px] sm:w-[450px] sm:left-1/2 sm:-translate-x-1/2 lg:left-auto lg:translate-x-0  lg:w-auto lg:mx-[14px]">
      <div className="w-full">
        {children}
        <AnimatedTransition show={showCloseButton && !isSuccess}>
          <div className="flex justify-center mt-[20px]">
            <PrimaryButton
              text={'common.technicalDefeat'}
              onClick={closeUnusedRoomHandler}
            />
          </div>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer && !showCloseButton}>
          {!isMobile && <div className="h-[40px]" />}
          <DigitalClock
            key={closerRoom?.deadline.toString()}
            className="absolute  left-1/2 transform -translate-x-1/2 bottom-[-50px]"
            expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
            onExpire={onExpire}
          />
        </AnimatedTransition>
      </div>
    </Modal>,
    hostElement as Element
  );
};

export default WithTimerLogicModalHOC;
