import { useAccount } from 'wagmi';
import { useGlobalStore } from 'shared/store/globalStore';

export const useHash = () => {
  const { address } = useAccount();
  const { jwt, jwtValidUntil } = useGlobalStore();
  const handleSetHash = async ({
    hashKey,
    hashValue,
  }: {
    hashKey: string;
    hashValue: string;
  }) => {
    try {
      let date = jwtValidUntil[address!];
      
      if (!date) {
        return;
      }

      await fetch('https://rps-hash.rockpapescissorsgame.workers.dev/api/hash', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          hashKey,
          hashValue,
          address,
          signature: jwt[address!],
          message: `address: ${address} \nvalid until: ${new Date(
            date
          ).toISOString()}`,
        }),
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleGetHash = async ({ hashKey }: { hashKey: string }) => {
    const maxRetries = 3;
    const baseDelay = 300;
    let attempt = 0;
    let date = jwtValidUntil[address!];

    if (!date) {
      return;
    }

    let message = `address: ${address} \nvalid until: ${new Date(
      date
    ).toISOString()}`;
    message = encodeURIComponent(message);

    while (attempt < maxRetries) {
      try {
        const response = await fetch(
          `https://rps-hash.rockpapescissorsgame.workers.dev/api/hash?hashKey=${hashKey}&message=${message}&signature=${
            jwt[address!]
          }&address=${address}`,
          {
            method: 'GET',
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } else if (response.status >= 500) {
          console.error(
            `Attempt ${attempt + 1}: Server returned status ${response.status}`
          );
        } else {
          console.error(
            `Attempt ${attempt + 1}: Server returned status ${
              response.status
            }. No retry will be attempted.`
          );
          break;
        }
      } catch (error) {
        console.error(`Attempt ${attempt + 1}: Error fetching hash -`, error);
      }

      attempt++;
      if (attempt < maxRetries) {
        const delay = baseDelay * Math.pow(2, attempt - 1);
        console.log(`Retrying in ${delay} ms...`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        console.error('Max retries reached. Unable to retrieve hash.');
      }
    }
    return null;
  };

  return {
    handleSetHash,
    handleGetHash,
  };
};
