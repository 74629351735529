import logo from '@/assets/png/logo.png';
import ControllerIcon from '@/assets/controller.svg?react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@/assets/arrow-left.svg?react';
import { BurgerMenu } from '@/shared/components/sections/burger-menu';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/shared/constants';
import { useAccount } from 'wagmi';
import { useLocation } from 'react-router-dom';

interface HeaderProps {
  title?: React.ReactNode;
}

export const Header = ({ title }: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isConnected } = useAccount();

  const handleRedirectToHome = () => {
    navigate(ROUTES.HOME);
  };

  const handleRedirectToLobby = () => {
    navigate(ROUTES.LOBBY);
  };
  const location = useLocation();
  return (
    <div className="grid grid-cols-3 w-full px-[16px] lg:px-[64px] py-[16px] items-center">
      <div className="flex items-center gap-[24px]">
        <div onClick={handleRedirectToHome}>
          <img
            className="w-[61px] sm:w-[90px] lg:w-[94px] cursor-pointer"
            src={logo}
            alt={'rock paper scissors logo'}
          />
        </div>

        <div className="w-[3px] bg-[#272D63] h-[56px] hidden lg:flex" />

        <div
          className="text-[#C0C0FF] font-[600] text-[20px] items-center gap-[8px] cursor-pointer hidden lg:flex"
          onClick={handleRedirectToLobby}
        >
          {/*<ArrowLeftIcon />*/}
          {/*Back to streams*/}
        </div>
      </div>
      <div className="gradient-title text-[16px] sm:text-[30px] lg:text-[40px] font-bold flex justify-center items-center gap-[8px]">
        {title}
      </div>

      <div className="flex justify-end items-center gap-[24px]">
        {isConnected && location.pathname !== ROUTES.LOBBY && ( // and not on lobby page now
          <div
            className={`justify-center gap-2 rounded-[16px] border border-[#FFE081] m-[1px] h-[54px] sm:px-[35px] items-center bg-create-btn text-white font-semibold text-[16px] sm:text-[22px] hidden lg:flex cursor-pointer`}
            onClick={handleRedirectToLobby}
          >
            <ControllerIcon className="w-5 h-5" />
            {t('pages.liveGamesSection.create')}
          </div>
        )}
        <div className="w-[3px] bg-[#272D63] h-[56px] hidden lg:flex" />
        <BurgerMenu className="h-[46px] w-[46px]" />
      </div>
    </div>
  );
};
