import React, { useEffect, useState } from 'react';
import Allowance from 'modules/Room/features/Allowance/Allowance';
import { useTranslation } from 'react-i18next';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import Select, { selectOption } from 'shared/components/ui/Select/Select';
import BetInput from 'shared/components/ui/BetInput/BetInput';
import { formatNumber } from 'shared/web3/utils';
import { useBalance } from 'shared/hooks/useBalance';
import { useAccount } from 'wagmi';
import GuysLoader from 'shared/components/ui/Loaders/GuysLoader/GuysLoader';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { allowanceMessages } from 'shared/constants/text-loaders';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { OrangeButton } from '@/shared/components/ui/buttons';
import  SearchIcon from 'assets/search.svg?react';

import boxHalf from 'assets/png/box-half.png';
import { TimeAgo } from 'shared/components/ui/time-ago/time-ago';
import floor from 'assets/png/floor.png';
import { useBlockToFindCreateAndJoin } from 'modules/Room/Hooks/useBlockToFindCreateAndJoin';
import { Switch } from "@/components/ui/switch";

const regex = /^-?\d*\.?\d+$/;

interface Props {
  openLiveGames: () => void;
}
const chains = {
  1: 'ETH',
  137: 'Polygon',
  42161: 'Arbitrum',
  8453: 'Base',
};

export const CreateGameSectionMobile = ({ openLiveGames }: Props) => {
  const { t } = useTranslation();
  const [bet, setBet] = useState('5');
  const [betIsValid, setBetIsValid] = useState(true);
  const [allowancePending, setAllowancePending] = useState(false);
  const [allowanceFetching, setAllowanceFetching] = useState(false);
  const [fullAllowance, setFullAllowance] = useState(true);
  const { address, chainId } = useAccount();
  const { blockNumber, time } =
    useBlockToFindCreateAndJoin();
  const {
    setUserBet,
    currencyForSelect,
    selectedCurrency,
    setSelectedCurrency,
    userBets,
  } = useCurrenciesStore();

  const { allowanceTransactions } = usePlayerGamesStore();

  useEffect(() => {
    if (selectedCurrency && chainId) {
      setBet(
        String(
          userBets?.[chainId]?.[selectedCurrency?.symbol] ??
            selectedCurrency.bet[1]
        )
      );
    }
  }, [selectedCurrency, chainId]);

  const handleSelectCurrency = (currency: selectOption) => {
    setSelectedCurrency(currency.value as string);
  };
  const handleSetBetInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.,]/g, '');
    value = value.replace(/,/g, '.');
    value = value.replace(/(\..*)\./g, '$1');
    value = value.replace(/^\./, '');
    if (value.startsWith('0') && value[1] && !['.', ','].includes(value[1])) {
      value = `0.${value.slice(1)}`;
    }
    let moreThanMin = +value >= selectedCurrency!.minBet;
    let valid = regex.test(value) && moreThanMin;
    setBetIsValid(valid);
    if (valid && chainId) {
      setUserBet(chainId, +value, selectedCurrency!.symbol);
    }
    setBet(value);
  };
  const handleSetBet = (value: string) => {
    const regex = /^-?\d*\.?\d+$/;
    setBetIsValid(regex.test(value));
    setBet(value);
    if (chainId) setUserBet(chainId, +value, selectedCurrency!.symbol);
  };
  const { balance } = useBalance({
    currencyAddress: selectedCurrency?.address,
    walletAddress: address as `0x${string}`,
  });

  if (!selectedCurrency) return null;

  return (
    <div className="relative w-full h-full  min-h-[600px] flex-grow">
      <AnimatedTransition show={allowancePending}>
        <GuysLoader
          hash={allowanceTransactions.hash}
          messages={allowanceMessages}
          className="absolute top-0 z-[999] left-0 rounded-[4px] sm:rounded-[14px] w-full h-full bg-[#000014D9] bg-opacity-85 "
        />
      </AnimatedTransition>
      <div className="p-[20px] relative w-full  h-full">
        <OrangeButton
          onClick={openLiveGames}
          className="mb-10 flex items-center justify-center"
        >
          <SearchIcon className="mr-2 w-4 h-4" color="#FFC000" />
          <span className="text-orange-btn text-[10px]">
            {t('pages.liveGamesSection.find')}
          </span>
        </OrangeButton>
        <div className="font-bold text-[14px] gradient-title mb-3">
          {t('modals.setBet.select')}
        </div>
        <div className="flex w-full mb-[14px] items-start justify-between border-border border-b pb-[14px]">
          <div className="w-[70%] sm:flex-grow flex flex-col  mr-[10px] sm:mr-[22px]">
            <BetInput
              value={bet.toString()}
              valueHandler={handleSetBetInput}
              type="number"
              className="sm:flex-grow"
              balance={balance ?? 0}
              disabled={allowancePending}
            />
          </div>
          <Select
            className="flex-grow"
            options={currencyForSelect}
            selectedOption={{
              value: selectedCurrency.symbol,
              label: selectedCurrency.symbol,
            }}
            onSelect={handleSelectCurrency}
            disabled={allowancePending}
          />
        </div>
        <div className="flex items-center flex-wrap justify-between pb-[20px] mb-[20px]">
          <p className="text-simple-text text-[10px]">
            {t('modals.setBet.popular')}
          </p>
          {selectedCurrency?.bet &&
            selectedCurrency.bet.map((betValue, index) => (
              <button
                className="text-light-grape text-[12px] sm:text-[16px]  border-[1px] border-indigo rounded-[8px] p-2.5 min-w-[76px]"
                onClick={() => handleSetBet(betValue)}
                disabled={allowancePending}
              >
                {formatNumber(+betValue)} {selectedCurrency.symbol}
              </button>
            ))}
        </div>
      </div>
      <div className="absolute bottom-[110px] z-50 px-4 flex ">
        <p className="text-[10px] text-[#C1C0CC] ">{t('modals.setBet.plan')}</p>
        <Switch
          className="ml-2"
          onCheckedChange={() => {
            setFullAllowance(!fullAllowance);
          }}
          checked={fullAllowance}
        />
      </div>
      <img
        src={boxHalf}
        alt="box"
        className="w-[160px] h-[160px] absolute bottom-[150px] z-30 left-1/2 transform -translate-x-1/2"
      />
      <img
        src={floor}
        alt="floor"
        className="absolute bottom-[90px] left-0 right-0 z-0 h-[20%] w-full"
      />
      <div className="bg-grape flex gap-3 justify-center items-center flex-col py-4 fixed bottom-0 w-full z-50">
        <Allowance
          preferredBet={+bet}
          betIsValid={betIsValid}
          setAllowancePending={setAllowancePending}
          setAllowanceFetching={setAllowanceFetching}
          balance={balance}
          fullAllowance={fullAllowance}
          mobile
        />
        <div className="text-[8px] text-block-text flex gap-2">
          {!!blockNumber && (
            <span>
              {chainId && chains[chainId as keyof typeof chains]} block: {+blockNumber.toString()}
            </span>
          )}
          <span>|</span>
          <TimeAgo time={time} />
        </div>
      </div>
    </div>
  );
};
