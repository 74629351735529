import React, { useEffect, useState } from 'react';
import { Header } from 'shared/components/sections/header/header';
import { useTranslation } from 'react-i18next';
import { TableBar } from 'modules/explore-games/pages/components/table-bar';
import { usePublicClient } from 'wagmi';
import { useExploreStore } from 'modules/explore-games/store/store';
import { ExploreCard } from 'modules/explore-games/components/explor-card';
import { Spinner } from 'shared/components/ui/Loaders/spinner/spinner';
import { useAccount } from 'wagmi';

export const ExploreGames = () => {
  const { t } = useTranslation();
  const publicClient = usePublicClient();
  let { chainId } = useAccount();
  let { startStore, activeRooms, playedRooms, loading, clearStore } =
    useExploreStore();
  useEffect(() => {
    if (publicClient) {
      clearStore();
      startStore(publicClient);
    }
  }, [publicClient, chainId]);
  return (
    <div className="w-full min-h-[100vh]" key={chainId}>
      <Header title={t('pages.explore')} />
      {loading && (
        <div className="w-full h-[calc(100vh-90px)] flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className="lg:px-[64px] max-w-[1920px] px-[16px] flex flex-col justify-center mx-auto ">
        {/*<TableBar />*/}
        {!!activeRooms?.length && (
          <>
            <div className="flex items-center gap-6 mb-6">
              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>

              <p className="font-medium text-2xl text-[rgba(247,247,247,0.4)]">
                Active Games
              </p>

              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-8 mb-6">
              {activeRooms.map((room, index) => (
                <ExploreCard key={index} room={room} />
              ))}
            </div>
          </>
        )}

        {!!playedRooms?.length && (
          <>
            <div className="flex items-center  gap-6 mb-6">
              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>

              <p className="font-medium text-2xl text-[rgba(247,247,247,0.4)]">
                Game Replays
              </p>

              <div className="flex-grow h-[1px] bg-[rgba(255,255,255,0.25)]"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-8  pb-6">
              {playedRooms.slice(0, 21).map((room, index) => (
                <ExploreCard key={index} room={room} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
