import React, { useEffect } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import IconChain from 'shared/components/ui/IconChain/IconChain';
// import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { useRoomStore } from 'shared/store/roomStore';

export const ChainsSwitcher = () => {
  const { chains, switchChainAsync, isError, error } = useSwitchChain();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const { resetStore } = useRoomStore();
  useEffect(() => {
    if (isError) {
      console.log(error.message);
      Sentry.captureException(error);
    }
  }, [isError]);
  const chainSwitchHandler = async (chainId: number) => {
    await switchChainAsync({ chainId });
    const startWithZeroX = address?.startsWith('0x');
    const walletWithoutPrefix = startWithZeroX ? address?.substring(2) :address;
    // const eventOptions = {
    //   category: 'game',
    //   action: 'Choose chain',
    //   wallet: walletWithoutPrefix,
    //   startWithZeroX: startWithZeroX,
    //   chainId: chainId,
    // };
    // ReactGA.event('switch', eventOptions);
    navigate(ROUTES.LOBBY);
  };

  if (!chains) return null;

  return (
    <>
      {chains.map((chain, i) => (
        <div
          key={chain.id}
          className={`border border-border rounded-[12px] py-[22px] px-[20px] w-full ${
            i !== chains.length - 1 ? 'mb-[13px]' : ''
          }`}
        >
          <div className="flex justify-between w-full mb-[22px]">
            <div className="flex items-center text-[14px] sm:text-[26px] text-white font-medium">
              <IconChain chainId={chain.id} />
              <span className="pl-[12px]">{chain.name}</span>
            </div>
            <PrimaryButton
              text={'common.select'}
              onClick={() => chainSwitchHandler(chain.id)}
            />
          </div>
          <p className="text-white text-[16px] leading-[140%]">
            {t(`chains.${chain.id}`)}
          </p>
        </div>
      ))}
    </>
  );
};
