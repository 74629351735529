import React, { useEffect, useState } from 'react';
import TextWithLeftColorLine from 'shared/components/ui/TextWithLeftColorLine/TextWithLeftColorLine';
import WithTimerLogicModalHOC from '../../HOC/WithTimerLogicModalHoc';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { useRevealModalText } from 'modules/Room/RoomModals/components/ModalsPlayGame/modals/WaitYourRevealModal/useRevealModalText';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';
import {
  technicalDefeatMessages,
  waitYourRevealMessages,
} from 'shared/constants/text-loaders';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { useReveal } from 'modules/Room/Hooks/useReveal';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { SHOW_REVEAL_BTN } from 'shared/constants/timers';

const WaitYourRevealModal = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const [showRevealBtn, setShowRevealBtn] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { revealModalState, setRevealModalStateError } = useModalStore();
  useEffect(() => {
    return () => {
      setRevealModalStateError({ error: false, reject: false });
    };
  }, [setRevealModalStateError]);
  useEffect(() => {
    setTimeout(() => {
      setShowRevealBtn(true);
    }, SHOW_REVEAL_BTN);
  }, []);
  const { getTextComponent } = useRevealModalText(
    showTimer,
    showCloseButton,
    technicalDefeatError
  );
  const { chainId } = useAccount();
  const { closeHash, playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  const { roundCounter } = useRoomStore();
  const { writeReveal } = useReveal();

  return (
    <>
      <AnimatedTransition
        show={
          !!closeHash[chainId!]?.hash ||
          !!playMoves[activeRoomId! + address! + chainId + roundCounter]
            ?.revealHash?.hash
        }
      >
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={
            closeHash[chainId!]?.hash ||
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.revealHash?.hash
          }
          selector=".player-card-host"
          messages={
            !!closeHash[chainId!]?.hash
              ? technicalDefeatMessages
              : waitYourRevealMessages
          }
        />
      </AnimatedTransition>
      <AnimatedTransition
        show={
          !(
            !!closeHash[chainId!]?.hash ||
            !!playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.revealHash?.hash
          )
        }
      >
        <WithTimerLogicModalHOC
          key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
          selector={'.player-card-host'}
          modalName="waitYourReveal"
          setShowTimer={setShowTimer}
          setShowCloseButton={setShowCloseButton}
          setTechnicalDefeatError={setTechnicalDefeatError}
        >
          <TextWithLeftColorLine
            colorClass={
              !revealModalState.error &&
              !revealModalState.reject &&
              !technicalDefeatError
                ? 'bg-green'
                : 'bg-red'
            }
          >
            {getTextComponent()}
          </TextWithLeftColorLine>
          <AnimatedTransition show={showRevealBtn}>
            <div className="flex justify-center">
              <PrimaryButton
                text={'common.reveal'}
                onClick={writeReveal}
                className={'mt-[20px]'}
              />
            </div>
          </AnimatedTransition>
        </WithTimerLogicModalHOC>
      </AnimatedTransition>
    </>
  );
};

export default WaitYourRevealModal;
