import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { useTranslation } from 'react-i18next';
import { useRoomStore } from 'shared/store/roomStore';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { RoundOutcome } from 'shared/constants/shared-enums';

export const useWaitYourMoveModalText = (
  isSuccess: boolean,
  showTimer: boolean,
  showCloseButton: boolean,
  technicalDefeatError: boolean,
  roundOutcome: RoundOutcome
) => {
  const { playMoveModalState } = useModalStore();

  const { roundCounter } = useRoomStore();
  const { t } = useTranslation();

  const FirstRound = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.connected')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.tip')}
      </p>
    </>
  );

  const FirstRoundWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.connected')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerTip')}
      </p>
    </>
  );

  const NoneState = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.yourMove')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.select')}
      </p>
    </>
  );

  const YouWin = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.youWin')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.select')}
      </p>
    </>
  );

  const YouLose = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.youLose')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.select')}
      </p>
    </>
  );

  const Draw = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.draw')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.select')}
      </p>
    </>
  );

  const NoneStateWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.yourMove')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerSelect')}
      </p>
    </>
  );

  const YouWinWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.youWin')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerSelect')}
      </p>
    </>
  );

  const YouLoseWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.youLose')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerSelect')}
      </p>
    </>
  );

  const DrawWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.draw')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerSelect')}
      </p>
    </>
  );

  const ErrorText = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.error')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.chooseAgain')}
      </p>
    </>
  );

  const ErrorTextWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.error')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerChooseAgain')}
      </p>
    </>
  );

  const RejectText = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.reject')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.please')}
      </p>
    </>
  );

  const RejectTextWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.reject')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.timerChooseAgain')}
      </p>
    </>
  );

  const TechnicalDefeatAllStates = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.gameOver')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.technicalDefeatExplanation')}
      </p>
    </>
  );

  const TechnicalDefeatAllStatesError = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourMove.technicalDefeatError')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourMove.technicalDefeatErrorExplanation')}
      </p>
    </>
  );

  const notFirstRoundTextGenerator = () => {
    if (roundOutcome === RoundOutcome.None) return <NoneState />;
    if (roundOutcome === RoundOutcome.Draw) return <Draw />;
    if (roundOutcome === RoundOutcome.Win) return <YouWin />;
    if (roundOutcome === RoundOutcome.Lose) return <YouLose />;
  };
  const notFirstRoundTextGeneratorWithTimer = () => {
    if (roundOutcome === RoundOutcome.None) return <NoneStateWithTimer />;
    if (roundOutcome === RoundOutcome.Draw) return <DrawWithTimer />;
    if (roundOutcome === RoundOutcome.Win) return <YouWinWithTimer />;
    if (roundOutcome === RoundOutcome.Lose) return <YouLoseWithTimer />;
  };

  const getTextComponent = () => {
    return (
      <>
        <AnimatedTransition
          show={
            !playMoveModalState.error &&
            !playMoveModalState.reject &&
            !showCloseButton &&
            roundCounter !== 1 &&
            showTimer
          }
        >
          {notFirstRoundTextGeneratorWithTimer()}
        </AnimatedTransition>
        <AnimatedTransition
          show={
            !playMoveModalState.error &&
            !playMoveModalState.reject &&
            !showCloseButton &&
            roundCounter === 1 &&
            !showTimer
          }
        >
          <FirstRound />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            !playMoveModalState.error &&
            !playMoveModalState.reject &&
            !showCloseButton &&
            roundCounter === 1 &&
            showTimer
          }
        >
          <FirstRoundWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            !playMoveModalState.error &&
            !playMoveModalState.reject &&
            !showCloseButton &&
            roundCounter !== 1 &&
            !showTimer
          }
        >
          {notFirstRoundTextGenerator()}
        </AnimatedTransition>
        <AnimatedTransition
          show={
            playMoveModalState.error &&
            !isSuccess &&
            !showCloseButton &&
            showTimer
          }
        >
          <ErrorTextWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            playMoveModalState.error &&
            !isSuccess &&
            !showCloseButton &&
            !showTimer
          }
        >
          <ErrorText />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            playMoveModalState.reject &&
            !isSuccess &&
            !showCloseButton &&
            showTimer
          }
        >
          <RejectTextWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            playMoveModalState.reject &&
            !isSuccess &&
            !showCloseButton &&
            !showTimer
          }
        >
          <RejectText />
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton && technicalDefeatError}>
          <TechnicalDefeatAllStatesError />
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton && !technicalDefeatError}>
          <TechnicalDefeatAllStates />
        </AnimatedTransition>
      </>
    );
  };

  return { getTextComponent };
};
