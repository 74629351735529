import { useMediaQuery } from 'usehooks-ts';
import { DesktopView, MobileView } from './views';
import React, { useEffect } from 'react';
import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useActiveGameRedirect } from '@/modules/Room/Hooks/useActiveGameRedirect';

export const LobbyPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isConnected, chainId } = useAccount();
  const navigate = useNavigate();
  useActiveGameRedirect();
  useEffect(() => {
    if (!isConnected) {
      navigate(ROUTES.HOME);
    }
  }, [isConnected]);

  return isMobile ? (
    <MobileView key={chainId} />
  ) : (
    <DesktopView key={chainId} />
  );
};
