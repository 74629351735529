import playerAScoreBg from './assets/player-a-score.png';
import playerBScoreBg from './assets/player-b-score.png';
import versusBorders from './assets/versus-borders.png';
import './score-styles.scss';

interface ScoreProps {
  playerAWins: number;
  playerBWins: number;
}

export const Score = ({ playerAWins, playerBWins }: ScoreProps) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className='relative w-[45.5px] h-[36px] sm:w-[112px] sm:h-[89px] lg:w-[138px] lg:h-[108px] flex justify-center items-center mr-[-12px]'>
        <img src={playerAScoreBg} alt="player-a-score-bg" className='absolute top-0 left-0 w-[45.5px] sm:w-[112px] lg:w-[137px]' />
        <span className="text-[32px] sm:text-[80px] lg:text-[96px] font-digital score-player-a-gradient-title relative z-2">
          {`${playerAWins}`}
        </span>
      </div>
      <div className='relative w-[60px] sm:w-[126px] lg:w-[177px] flex justify-center items-center'>
        <img src={versusBorders} alt="versus-borders" className='absolute top-[-6px] sm:top-[-12px] lg:top-[-18px] left-[4px] sm:left-0 w-[51px] sm:w-[126px] lg:w-full' />
        <div className='w-[40px] h-[40px] sm:w-[99px] lg:w-[138px] sm:h-[99px] lg:h-[138px] rounded-[50%] border-[#202066] border-[1px] bg-[#01001D4D] text-[16px] sm:text-[40px] lg:text-[48px] font-bold text-white flex justify-center items-center'>vs</div>
      </div>
      <div className='relative w-[45.5px] h-[36px] sm:w-[112px] sm:h-[89px] lg:w-[138px] lg:h-[108px] flex justify-center items-center ml-[-12px]'>
        <img src={playerBScoreBg} alt="player-a-score-bg" className='absolute top-0 right-0 w-[45.5px] sm:w-[112px] lg:w-[137px]' />
        <span className="text-[32px] sm:text-[80px] lg:text-[96px] font-digital score-player-b-gradient-title relative z-2">
          {`${playerBWins}`}
        </span>
      </div>
    </div>
  );
};
