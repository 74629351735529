import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import boxFull from 'assets/png/box-full.png';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import info from 'assets/png/info.png';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { coinIcons } from 'shared/constants';

type CoinSymbol = keyof typeof coinIcons;

interface RoomBoxProps {
  bet: number | null;
  token: string;
  animationsTriggered: boolean;
}

export const RoomBox = ({
  bet,
  token,
  animationsTriggered,
}: RoomBoxProps) => {
  const [betText, setBetText] = useState('');
  const [showTip, setShowTip] = useState(false);
  const [rawBet, setRawBet] = useState<number | null>(null);
  const { availableCurrencies } = useCurrenciesStore();

  const boxRef = useRef<HTMLImageElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const betTextRef = useRef<HTMLParagraphElement>(null);

  const currency = availableCurrencies.find((item) => item.address.toLowerCase() === token.toLowerCase());

  // bet text
  useEffect(() => {
    if (bet && currency) {
      const roomBet = Number(bet) / 10 ** currency.decimals;

      setRawBet(roomBet);
      const betString = roomBet.toString();
      const [, decimals] = betString.split('.');
      let formattedBet = '';
      const leadingZeros = decimals?.match(/^0+/)?.[0]?.length || 0;

      if (leadingZeros >= 3) {
        formattedBet = `0.0<span class="text-[16px]">${leadingZeros}</span>${
          decimals.slice(leadingZeros)[0]
        }`;
      } else {
        formattedBet = roomBet.toString();
      }
      if (roomBet >= 1_000_000) {
        formattedBet = `${(roomBet / 1_000_000).toFixed(1)}M`;
      } else if (roomBet >= 1_000) {
        formattedBet = `${(roomBet / 1_000).toFixed(1)}K`;
      }

      setBetText(`${formattedBet}`);
    }
  }, [bet]);

  const getCoinIcon = (symbol: CoinSymbol | undefined) => {
    if (!symbol) return null;
    const IconComponent = coinIcons[symbol];
    return IconComponent ? <IconComponent className="w-6" /> : null;
  };
  const isMobileDevice = useMemo(
    () => /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    []
  );
  return (
    <div
      ref={boxRef}
      className={classNames(
        'absolute z-50 left-1/2 transform -translate-x-1/2 bottom-[110px] lg:bottom-0 z-1 w-[160px] sm:w-[220px] lg:w-[320px] blur-4',
        animationsTriggered
          ? 'animate-slide-in-from-bottom'
          : 'animate-fast-appear'
      )}
    >
      <img src={boxFull} alt="box" />
      <div
        ref={textContainerRef}
        className="absolute z-50 text-white text-center top-[69%] left-[9%] w-[45%] h-[18%] font-bold  flex items-center justify-center whitespace-nowrap"
      >
        {betText && (
          <div
            className="flex gap-2"
            ref={betTextRef}
          >
            <img
              src={info}
              alt="info"
              onMouseEnter={
                !isMobileDevice ? () => setShowTip(true) : undefined
              }
              onClick={() => setShowTip(!showTip)}
              onMouseLeave={
                !isMobileDevice ? () => setShowTip(false) : undefined
              }
              className="w-4 h-4 z-50 absolute top-1 right-1 cursor-pointer pointer-events-auto"
            />
            <AnimatedTransition asFragment={true} show={showTip}>
              <div
                className={`absolute top-[-20%]
                  left-full
                  box-border`}
              >
                <div className="bg-border border border-gray-border font-medium text-[18px] relative rounded-[8px] flex flex-col items-start px-[16px] sm:py-[12px] py-[5px] z-50">
                  <p className="text-white  ">
                    K <span className="opacity-60 ">- 1000</span>
                  </p>
                  <p className="text-white ">
                    M <span className="opacity-60 ">- 1000</span>
                  </p>
                  <p className="text-white ">
                    0.0<span className="text-[12px]">3</span>1{' '}
                    <span className="opacity-60 ">- 0.0001</span>
                  </p>
                  <p className="text-white border-t border-gray-border w-full text-start pt-1 mt-1">
                    Bet
                    <span className="opacity-60 "> - {rawBet}</span>
                  </p>
                </div>
              </div>
            </AnimatedTransition>
            <p
              className="font-black text-[16px] lg:text-[30px]"
              dangerouslySetInnerHTML={{ __html: betText }}
            ></p>
            {getCoinIcon(currency?.symbol as CoinSymbol | undefined)}
          </div>
        )}
      </div>
    </div>
  );
};
