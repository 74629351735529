import React, { useEffect, useState } from 'react';
import ModalWithFullScreenBg from 'shared/components/ui/Modal/ModalWithFullScreenBg';
import {
  ModalPositionEnum,
  ModalTypeEnum,
} from 'modules/Room/RoomModals/constants/modalEnums';
import TextWithLeftColorLine from 'shared/components/ui/TextWithLeftColorLine/TextWithLeftColorLine';
import SocialMediaBtn from 'shared/components/ui/SocialMediaBtn/SocialMediaBtn';
import IconTelegram from 'assets/telegram.svg?react';
import IconX from 'assets/x.svg?react';
import IconDiscord from 'assets/discord.svg?react';
import { SOCIAL_MEDIA } from 'shared/constants/socialMedia';
import { useTranslation } from 'react-i18next';
import { useWriteRspContractExitRoom } from 'shared/features/contract.abi';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { useParams } from 'react-router-dom';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { takeMoneyBackMessages } from 'shared/constants/text-loaders';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import MintingLoader from 'shared/components/ui/Loaders/MintingLoader/MintingLoader';

interface Props {
  open: boolean;
}

const NoOneJoinModal = ({ open }: Props) => {
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { t } = useTranslation();
  const { chainId } = useAccount();
  const { setNoOneJoinHash, noOneJoinHash } = usePlayerGamesStore();
  const {
    data,
    writeContractAsync: writeContractExit,
    isSuccess,
    error,
  } = useWriteRspContractExitRoom();
  const isMobile = useMediaQuery('(max-width: 1200px)');

  const { isSuccess: isExitSuccess, isError } = useTransactionReceipt(
    localHash,
    noOneJoinHash[chainId!]?.date
  );

  useEffect(() => {
    if (isSuccess) {
      setNoOneJoinHash(chainId!, data!, new Date());
      setLocalHash(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isExitSuccess) {
      setLocalHash(undefined);
      setNoOneJoinHash(chainId!, null);
    }
  }, [isExitSuccess]);

  useEffect(() => {
    if (error || isError) {
      console.error('Error exiting room', error);
      setNoOneJoinHash(chainId!, null);
      setLocalHash(undefined);
    }
  }, [error, isError]);
  useEffect(() => {
    if (chainId && noOneJoinHash?.[chainId!]?.hash) {
      setLocalHash(noOneJoinHash?.[chainId!]?.hash);
    }
  }, []);
  const { activeRoomId } = useParams();

  const handleExit = (activeRoomId: bigint) => async () => {
    if (!activeRoomId) return;
    await writeContractExit({
      args: [BigInt(activeRoomId)],
    });
  };

  const handleClick = (url: string) => () => {
    window.open(url, '_blank');
  };

  const handleShare = () => {
    const url = window.location.href;
    if (isMobile) {
      navigator.share({ url });
    } else {
      navigator.clipboard.writeText(url).then(() => {
        // TODO add blinking effect to button
      });
    }
  };


  return (
    <>
      <AnimatedTransition show={!!localHash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={localHash}
          messages={takeMoneyBackMessages}
          selector=".player-card-host"
        />
      </AnimatedTransition>
      <AnimatedTransition show={!localHash}>
        <ModalWithFullScreenBg
          open={open}
          key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
          position={
            isMobile ? ModalPositionEnum.CENTER : ModalPositionEnum.LEFT
          }
        >
          <div className="w-full p-[20px] sm:p-[30px]">
            <TextWithLeftColorLine colorClass="bg-orange" className="mb-[20px]">
              <p className="text-white text-[12px] sm:text-[17px] mb-[20px] text-justify">
                {t('modals.noOneJoin.situation')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px]  mb-[20px] text-justify	">
                {t('modals.noOneJoin.unpopularBet')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px] text-justify	">
                {t('modals.noOneJoin.proTip')}
              </p>
            </TextWithLeftColorLine>
            <div className="flex w-full justify-around mb-[20px]">
              <SocialMediaBtn
                onClick={handleClick(SOCIAL_MEDIA.TELEGRAM)}
                svg={<IconTelegram />}
              />
              <SocialMediaBtn
                onClick={handleClick(SOCIAL_MEDIA.TWITTER)}
                svg={<IconX />}
              />
              <SocialMediaBtn
                onClick={handleClick(SOCIAL_MEDIA.DISCORD)}
                svg={<IconDiscord />}
              />
            </div>
            <AnimatedTransition show={!isSuccess}>
              <div className="w-full flex justify-center">
                {/*<PrimaryButton*/}
                {/*  className="mx-auto"*/}
                {/*  text={'common.shareGame'}*/}
                {/*  onClick={handleShare}*/}
                {/*/>*/}
                <PrimaryButton
                  className="mx-auto"
                  text={'modals.takeABetBack.collectButton'}
                  onClick={handleExit(BigInt(activeRoomId || 0))}
                />
              </div>
            </AnimatedTransition>
          </div>
        </ModalWithFullScreenBg>
      </AnimatedTransition>
    </>
  );
};

export default NoOneJoinModal;
