import React, { useEffect } from 'react';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { useSwitchChain } from 'wagmi';
import { useParams } from 'react-router-dom';
import { CHAINS_IDS } from 'shared/config';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import molecules from 'assets/png/molecules.png';
import { useTranslation } from 'react-i18next';

interface ChangeNetworkProps {
  title: string;
  description: string;
}

export const ChangeNetwork = ({ title, description }: ChangeNetworkProps) => {
  const { switchChainAsync } = useSwitchChain();
  const { networkId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!CHAINS_IDS.includes(Number(networkId) as 1 | 42161 | 137 | 8453)) {
      navigate(ROUTES.HOME);
    }
  }, [networkId]);
  const chainSwitchHandler = async (chainId: number) => {
    await switchChainAsync({ chainId });
  };
  return (
    <div className="w-full h-[100vh] flex justify-center items-center overflow-hidden relative">
      <div className="p-6 bg-modal-gradient flex flex-col gap-5 items-center border-[2px] border-border rounded-[24px]">
        <img src={molecules} alt="molecules" className="w-[150px] h-[150px]" />
        <p className="gradient-title-gold max-w-[230px] text-center text-2xl  font-bold leading-none">
          {t(title)}
        </p>
        <p className="text-sm text-white max-w-[280px] text-center opacity-70">
          {t(description)}
        </p>
        <div className="w-full h-[1px] bg-border" />
        <PrimaryButton
          className={'w-full'}
          text={'common.switch'}
          onClick={() => chainSwitchHandler(Number(networkId))}
        />
      </div>
    </div>
  );
};
