import {
  useReadRspContractActiveRoomCounter,
  useReadRspContractGetRoomsInfo,
  useReadRspContractRoomIdCounter,
  useReadRspContractRooms,
} from 'shared/features/contract.abi';
import { useEffect, useState, useRef } from 'react';
import { RANGE_OF_GAME_REQUEST } from 'shared/constants/constants';
import { useAccount } from 'wagmi';
import { ROUTES } from 'shared/constants';
import {
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalStore } from 'shared/store/globalStore';
import { RoomInfo } from 'shared/types/app-types';

export const useActiveGameRedirect = () => {
  const { isConnected } = useAccount();
  const { activeRoomId } = useParams();
  const [roomIds, setRoomIds] = useState({
    fromRoomId: 0n,
    toRoomId: 0n,
  });
  const [activeRoom, setActiveRoom] = useState<RoomInfo | null>(null);
  const [activeRooms, setActiveRooms] = useState<RoomInfo[]>([]);
  const { address, chainId } = useAccount();
  const { activeGames } = useGlobalStore();
  const navigate = useNavigate();
  const hasNavigatedRef = useRef(false);
  const { data, isSuccess: roomIsSuccess } = useReadRspContractRooms({
    args: [BigInt(activeRoom?.data?.roomId ?? 0)],
    query: {
      enabled: !!activeRoom?.data?.roomId,
      staleTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  });

  const { data: activeRoomCounter, isSuccess: activeRoomCounterSuccess } =
    useReadRspContractActiveRoomCounter({
      query: {
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    });

  const { data: lastRoomId, isSuccess: lastRoomIdSuccess } =
    useReadRspContractRoomIdCounter({
      query: {
        staleTime: 0,
      },
    });
  const {
    data: roomsInfo,
    isSuccess: roomsInfoSuccess,
    refetch: refetchRoomsInfo,
  } = useReadRspContractGetRoomsInfo({
    args: [roomIds.fromRoomId, roomIds.toRoomId],
    query: {
      enabled: roomIds.toRoomId !== 0n,
      staleTime: 0,
      refetchOnMount: true,
    },
  });

  useEffect(() => {
    if (data && roomIsSuccess && isConnected && activeRoom?.data?.roomId) {
      const [
        innerData,
        pot,
        roundCounter,
        winsCounter,
        closer,
        deadline,
        status,
      ] = data;
      if (status !== RoomStatusEnum.Closed && !activeRoomId) {
        navigate(`${ROUTES.ROOM}/${chainId}/${activeRoom?.data?.roomId}`);
      }
    }
  }, [data, isConnected, roomIsSuccess, activeRoom, chainId]);

  useEffect(() => {
    if (
      address &&
      activeGames &&
      activeGames[chainId! + address!.toLowerCase()] && activeRoomId !== activeGames[chainId! + address!.toLowerCase()]
    ) {
      navigate(
        `${ROUTES.ROOM}/${chainId}/${activeGames[chainId! + address!.toLowerCase()]}`
      );
      hasNavigatedRef.current = true;
    }
  }, [activeGames, chainId, navigate, address]);

  useEffect(() => {
    if (lastRoomId && lastRoomIdSuccess && !hasNavigatedRef.current) {
      setRoomIds({
        fromRoomId:
          lastRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
            ? 0n
            : lastRoomId - BigInt(RANGE_OF_GAME_REQUEST),
        toRoomId: lastRoomId ?? 0n,
      });
    }
  }, [lastRoomId, lastRoomIdSuccess]);

  useEffect(() => {
    if (roomsInfo && roomsInfoSuccess && !hasNavigatedRef.current) {
      setActiveRooms((prev) => [
        ...prev,
        ...roomsInfo.filter(
          (room: any) =>
            room.status !== RoomStatusEnum.Closed && room.data.roomId !== 0n
        ),
      ]);
    }
  }, [roomsInfo, roomsInfoSuccess]);

  useEffect(() => {
    if (
      activeRoomCounterSuccess &&
      activeRoomCounter > 0n &&
      roomsInfoSuccess &&
      !hasNavigatedRef.current &&
      address
    ) {
      const activeRoom = activeRooms.findLast((item) => {
        return (
          [
            item.data.playerA.toLowerCase(),
            item.data.playerB.toLowerCase(),
          ].includes(address.toLowerCase()) &&
          item.status !== RoomStatusEnum.Closed &&
          item.winnerStatus === WinnerStatusEnum.None
        );
      });
      if (activeRooms.length < Number(activeRoomCounter) && !activeRoom) {
        setRoomIds({
          fromRoomId:
            roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
              ? 0n
              : roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST),
          toRoomId: roomIds.fromRoomId,
        });
      }
      if (activeRoom) setActiveRoom(activeRoom);
    }
  }, [
    activeRooms,
    activeRoomCounter,
    roomsInfoSuccess,
    activeRoomCounterSuccess,
  ]);
};
