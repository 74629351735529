import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

export const Badge = ({ text, onClick, icon }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`gap-4 border-[2px] flex items-center bg-indigo rounded-[24px] border-border px-4 py-2 text-[20px] text-light-grape
      ${onClick ? 'cursor-pointer' : ''}
    `}
      onClick={onClick}
    >
      {icon}
      {t(text)}
    </div>
  );
};
