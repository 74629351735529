import { PlayerType, RoomStatus } from 'shared/types/app-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  readRoomIdCounter,
  readActiveRoomCounter,
  readGetRoomsInfo,
} from 'shared/viem-functions/find-rooms';
import { decodeEventLog, PublicClient, Log } from 'viem';
import {
  CloserEnum,
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { NULL_ADDRESS } from 'shared/constants/constants';
import contract from 'shared/contract/contract.abi.json';
import { fetchBlockNumber } from 'shared/viem-functions/block-numbers';

export interface RoomData {
  roomId: number | null;
  playerA: PlayerType;
  playerB: PlayerType;
  bet: number | null;
  token: `0x${string}`;
  roomStatus: RoomStatusEnum;
  winsTo: number;
  roundCounter: number;
  closer: CloserEnum;
  deadline: bigint;
  winnerStatus: WinnerStatusEnum;
}

interface State {
  activeRooms: RoomData[];
  playedRooms: RoomData[];
  lastRoomId: number;
  lastFetched: number;
  loading: boolean;
}

interface Actions {
  clearStore: () => void;
  startStore: (provider: PublicClient) => Promise<void>;
}

const initialState: State = {
  activeRooms: [],
  playedRooms: [],
  lastRoomId: 0,
  lastFetched: 0,
  loading: true,
};

let fromBlock: bigint | undefined;
let processing = false;
let interval;

const fetchRoomEvents = async (
  publicClient: PublicClient
): Promise<
  | {
      removeFromActive: number[];
      addToActive: number[];
      updateGames: number[];
    }
  | undefined
> => {
  try {
    const toBlock = await fetchBlockNumber();
    if (!fromBlock) {
      fromBlock = toBlock;
    }
    if (fromBlock === toBlock) return;

    const logs: Log[] = await publicClient.getLogs({
      fromBlock,
      toBlock,
      address: import.meta.env.VITE_RSP_CONTRACT_ADDRESS,
    });
    fromBlock = toBlock;
    const decodedLogs = logs.map((log) => {
      try {
        return decodeEventLog({
          abi: contract,
          data: log.data,
          topics: log.topics,
        });
      } catch (error) {
        console.error('Error decoding log:', error);
        return null;
      }
    });

    const conclusion = {
      removeFromActive: [] as number[],
      addToActive: [] as number[],
      updateGames: [] as number[],
    };

    decodedLogs
      .filter(
        (event) =>
          event !== null &&
          (event.eventName === RoomStatus.ROOM_CLOSED ||
            event.eventName === RoomStatus.ROOM_JOINED ||
            event.eventName === 'PlayerRevealedMove')
      )
      .forEach((item: any) => {
        if (item.eventName === RoomStatus.ROOM_CLOSED) {
          conclusion.removeFromActive.push(item.args.roomId);
        }
        if (item.eventName === RoomStatus.ROOM_JOINED) {
          conclusion.addToActive.push(item.args.roomId);
        }
        if (item.eventName === 'PlayerRevealedMove') {
          conclusion.updateGames.push(item.args.roomId);
        }
      });
    conclusion.removeFromActive = [...new Set(conclusion.removeFromActive)];
    conclusion.addToActive = [...new Set(conclusion.addToActive)];
    conclusion.updateGames = [...new Set(conclusion.updateGames)];
    return conclusion;
  } catch (error) {
    console.error('Error fetching or decoding logs:', error);
  }
};

export const useExploreStore = create<State & Actions>()(
  immer(
    devtools((set, get) => ({
      ...initialState,
      startStore: async (provider: PublicClient) => {
        try {
          fromBlock = await fetchBlockNumber();
          const lastRoomIdBig = await readRoomIdCounter(provider);
          const activeRoomCounterBig = await readActiveRoomCounter(provider);
          const lastRoomId = Number(lastRoomIdBig);
          const activeRoomCounter = Number(activeRoomCounterBig);

          if (activeRoomCounter > 0) {
            let lastFetched = Math.max(lastRoomId - 50, 0);

            const rooms = await readGetRoomsInfo(
              provider,
              lastFetched,
              lastRoomId
            );
            const activeRooms: RoomData[] = [];
            const playedRooms: RoomData[] = [];

            rooms.forEach((room) => {
              if (room.data.playerB !== NULL_ADDRESS) {
                if (
                  room.status === RoomStatusEnum.Closed &&
                  room.data.playerB !== NULL_ADDRESS
                ) {
                  playedRooms.push(room);
                } else if (room.data.playerB !== NULL_ADDRESS) {
                  activeRooms.push(room);
                }
              }
            });

            set({
              activeRooms,
              playedRooms,
              lastRoomId,
              lastFetched,
              loading: false,
            });

            while (activeRooms.length < activeRoomCounter && lastFetched > 0) {
              const newLastFetched = Math.max(lastFetched - 50, 0);
              const additionalRooms = await readGetRoomsInfo(
                provider,
                newLastFetched,
                lastFetched
              );

              additionalRooms.forEach((room) => {
                if (room.status === RoomStatusEnum.Closed) {
                  playedRooms.push(room);
                } else {
                  activeRooms.push(room);
                }
              });

              lastFetched = newLastFetched;
              set({ activeRooms, playedRooms, lastRoomId, lastFetched });

              if (activeRooms.length >= activeRoomCounter) break;
            }
          }
        } catch (error) {
          console.error('Error starting store:', error);
        }
        interval = setInterval(async () => {
          console.log('processing');
          if (processing) return;
          processing = true;
          let conclusion = await fetchRoomEvents(provider);
          console.log(conclusion);
          if (conclusion) {
            const { removeFromActive, addToActive, updateGames } = conclusion;
            const { activeRooms, playedRooms } = get();
            if (addToActive.length) {
              const newActiveRooms = await Promise.all(
                addToActive.map(async (roomId) => {
                  const room = await readGetRoomsInfo(provider, roomId, roomId);
                  return room[0];
                })
              );
              set({ activeRooms: [...newActiveRooms, ...activeRooms] });
            }
            if (updateGames.length) {
              const updatedGames = await Promise.all(
                updateGames.map(async (roomId) => {
                  let room = await readGetRoomsInfo(provider, roomId, roomId);
                  return room[0];
                })
              );
              console.log(updatedGames);
              set({
                activeRooms: activeRooms.map(
                  (room) =>
                    updatedGames.find(
                      (updatedRoom) =>
                        updatedRoom.data.roomId === room.data.roomId
                    ) || room
                ),
              });
              if (removeFromActive.length) {
                set({
                  playedRooms: [
                    ...activeRooms.filter((room) =>
                      removeFromActive.includes(room.roomId)
                    ),
                    ...playedRooms,
                  ],
                  activeRooms: activeRooms.filter(
                    (room) => !removeFromActive.includes(room.data.roomId)
                  ),
                });
              }
            }
            processing = false;
          }
        }, 30000);
      },
      clearStore: () => {
        clearInterval(interval);
        set(initialState);
      },
    }))
  )
);
