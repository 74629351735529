import { useCallback, useEffect, useState, useRef } from 'react';
import {
  NULL_ADDRESS,
  RANGE_OF_GAME_REQUEST,
  TOTAL_WINS_TO,
} from 'shared/constants/constants';
import {
  useReadRspContractGetRoomsInfo,
  useReadRspContractRoomIdCounter,
} from 'shared/features/contract.abi';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { useGlobalStore } from 'shared/store/globalStore';
import useJoinGame from 'modules/Room/Hooks/useJoinGame';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import useInitGame from 'modules/Room/Hooks/useInitGame';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
// import ReactGA from 'react-ga4';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

export const useFindGames = () => {
  const { bet } = useRoomStore();
  const [joinRoomId, setJoinRoomId] = useState<bigint | null>(null);
  const [startNewGame, setStartNewGame] = useState(false);
  const [fromRoomId, setFromRoomId] = useState<bigint | null>(null);
  const createRoomRef = useRef(false);
  const navigate = useNavigate();
  const {
    playerB,
    setIsGameSearching,
    isGameSearching,
    joinRoomId: joinRoomIdStore,
    setRefetchLastRoomId,
  } = useRoomStore();
  const invitedBy = useGlobalStore((state) => state.invitedBy);
  const { address, chainId } = useAccount();

  const { closeAllModals, setModalState, modalState } = useModalStore();

  const { selectedCurrency } = useCurrenciesStore();

  const { joinHash, startHash } = usePlayerGamesStore();

  const betInTokenAmount = selectedCurrency?.decimals
    ? BigInt(bet * 10 ** selectedCurrency!.decimals)
    : 0n;

  const {
    data: lastRoomId,
    refetch: refetchLastRoomId,
    isSuccess: lastRoomIdSuccess,
    isError: lastRoomIdError,
    isFetching: lastRoomIdIsFetching,
  } = useReadRspContractRoomIdCounter();

  useEffect(() => {
    setRefetchLastRoomId(refetchLastRoomId);
  }, []);
  useEffect(() => {
    if (lastRoomId) {
      setFromRoomId(
        lastRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
          ? 0n
          : lastRoomId - BigInt(RANGE_OF_GAME_REQUEST)
      );
    }
  }, [lastRoomId]);
  const { data: roomsInfo, error } = useReadRspContractGetRoomsInfo({
    args: [fromRoomId ?? 0n, lastRoomId ?? 0n],
    query: {
      enabled: typeof fromRoomId === 'bigint',
    },
  });

  const writeJoinRoom = useJoinGame();
  const writeInitGame = useInitGame();

  const handleWriteInitGame = () => {
    if (
      createRoomRef.current ||
      modalState[ModalTypeEnum.CREATE_NEW_ROOM] ||
      (chainId && joinHash[chainId])
    )
      return;
    setIsGameSearching(false);
    console.log('init room');
    navigate(`${ROUTES.ROOM}/${chainId}`);
    setModalState(ModalTypeEnum.CREATE_NEW_ROOM, true);
    createRoomRef.current = true;
    try {
      writeInitGame({
        args: [
          Number(TOTAL_WINS_TO),
          selectedCurrency!.address,
          betInTokenAmount,
          NULL_ADDRESS,
          invitedBy,
        ],
      });
    } catch (error) {
      console.log('write init game error', error);
    }
  };

  const joinOrCreateRoom = useCallback(() => {
    if (
      playerB.address === NULL_ADDRESS &&
      !modalState[ModalTypeEnum.CREATE_NEW_ROOM]
    ) {
      // const roomToJoin = roomsInfo!.find(
      //   (room) =>
      //     room.data.bet === betInTokenAmount &&
      //     room.data.playerA.toLowerCase() !== address?.toLowerCase() &&
      //     room.data.token.toLowerCase() ===
      //       selectedCurrency!.address.toLowerCase() &&
      //     room.status === RoomStatusEnum.Open
      // );
      // if (roomToJoin) {
      //   const startWithZeroX = address?.startsWith('0x');
      //   const walletWithoutPrefix = startWithZeroX ? address?.substring(2) :address;
      //   const eventOptions = {
      //     category: 'game',
      //     action: 'Game Joined',
      //     wallet: walletWithoutPrefix,
      //     startWithZeroX: startWithZeroX,
      //     chainId: chainId,
      //     bet: bet,
      //     roomId: Number(roomToJoin.data.roomId),
      //   };
      //   // ReactGA.event('join', eventOptions);
      //   // if (window.twq) {
      //   //   window.twq('event', 'tw-op1jf-op1jr', {
      //   //     conversion_id: null
      //   //   });
      //   // }
      //   setStartNewGame(false);
      //   closeAllModals();
      //   setModalState(ModalTypeEnum.CONNECT_TO_PLAYER, true);
      //   setJoinRoomId(roomToJoin.data.roomId);
      // } else {
        console.log('startNewGame');
        handleWriteInitGame();
        createRoomRef.current = false;
      // }
    }
  }, [roomsInfo, selectedCurrency, betInTokenAmount, address, playerB, bet]);

  useEffect(() => {
    setJoinRoomId(joinRoomIdStore);
  }, [joinRoomIdStore]);

  useEffect(() => {
    if (
      roomsInfo &&
      isGameSearching &&
      lastRoomIdSuccess &&
      !lastRoomIdIsFetching &&
      !createRoomRef.current &&
      !joinRoomIdStore
    ) {
      joinOrCreateRoom();
    }
  }, [
    roomsInfo,
    isGameSearching,
    joinRoomId,
    lastRoomIdSuccess,
    lastRoomIdIsFetching,
  ]);

  useEffect(() => {
    if (error) {
      console.log('find games error', error);
      Sentry.captureException(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      !startNewGame &&
      joinRoomId !== null &&
      !createRoomRef.current &&
      chainId &&
      !startHash[chainId]
    ) {
      console.log('Write join room', joinRoomId);
      useRoomStore.getState().setJoinRoomId(joinRoomId);
      writeJoinRoom({ args: [joinRoomId, invitedBy] });
    }
  }, [joinRoomId, startNewGame, startHash, chainId]);
};
