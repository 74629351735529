import React from 'react';
import MainPageNetworkBtn from 'shared/components/ui/MainPageNetworkBtn/MainPageNetworkBtn';
import ArrowRightIcon from 'assets/arrow-right.svg?react';
import ConnectIcon from 'assets/connect.svg?react';
import BalanceIcon from 'assets/balance.svg?react';
import NetworkIcon from 'assets/network.svg?react';

interface Props {
  isConnected: boolean;
}

const PlaySequence = ({isConnected}: Props) => {
  return (
    <div className="w-full flex justify-between min-w-max	">
      <MainPageNetworkBtn
        active={!isConnected}
        svg={
          <ConnectIcon className="w-[28px] h-[28px] sm:w-[44px] sm:h-[44px]" />
        }
        labelYellow={'common.connect'}
        labelWhite={'common.wallet'}
      />
      <ArrowRightIcon className="mt-[19px] sm:mt-[23px] sm:w-[40px] sm:h-[30px]" />
      <MainPageNetworkBtn
        active={isConnected}
        svg={
          <NetworkIcon className="w-[28px] h-[28px] sm:w-[44px] sm:h-[44px]" />
        }
        labelYellow={'common.select'}
        labelWhite={'common.network'}
      />
      <ArrowRightIcon className="mt-[19px] sm:mt-[23px] sm:w-[40px] sm:h-[30px]" />
      <MainPageNetworkBtn
        active={false}
        svg={
          <BalanceIcon className="w-[28px] h-[28px] sm:w-[44px] sm:h-[44px]" />
        }
        labelYellow={'common.set'}
        labelWhite={'common.balance'}
      />
    </div>
  );
};

export default PlaySequence;