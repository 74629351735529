import React, { useState, useEffect } from 'react';
import guys1 from 'assets/animation/guys/guys1-min.png';
import guys2 from 'assets/animation/guys/guys2-min.png';
import LinkSvg from 'assets/link.svg?react';
import './GuysAnimation.scss';
import { useAccount } from 'wagmi';
import { useTranslation } from 'react-i18next';
import { networkHandler } from 'shared/utils/networkHandler';
import ReactDOM from 'react-dom';

const guys = [guys2, guys1];

interface Props {
  messages?: string[];
  className?: string;
  hash?: `0x${string}` | null;
  selector?: string;
}

const GuysLoader = ({ messages, className, hash, selector }: Props) => {
  const { chainId } = useAccount();
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (messages && messages.length > 0) {
      interval = setInterval(() => {
        setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [messages]);
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    let timeout: ReturnType<typeof setTimeout>;

    const startAnimation = () => {
      setIsAnimating(true);
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % guys.length);
      }, 200);
      timeout = setTimeout(() => {
        clearInterval(interval);
        setIsAnimating(false);
      }, 1200);
    };

    if (isAnimating) {
      startAnimation();
    } else {
      timeout = setTimeout(() => {
        startAnimation();
      }, 2000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isAnimating]);

  const content = (
    <div
      className={`flex justify-center items-center flex-col w-[400px]  ${
        className ? className : ''
      }`}
    >
      <div className="relative flex w-[400px] justify-center">
        <div className={`animation-container`}>
          <img
            src={guys[index]}
            alt="Animated character"
            className="animated-image"
          />
        </div>
        {messages && messages.length >= 0 && (
          <div className="absolute top-[200px] flex flex-col items-center">
            <div className="bg-loader-border h-[2px] w-full" />
            <div className="bg-loader-content text-center text-wrap flex flex-col w-[90%] py-[16px] px-[30px]">
              {hash && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white flex justify-center text-[12px] sm:text-[16px]"
                  href={`${networkHandler(chainId!)}/${hash}`}
                >
                  <LinkSvg className="h-[18px] w-[16px] sm:h-[22px] sm:w-[22px] mr-[10px] text-white" />{' '}
                  {t('common.transaction')}...
                  {hash!.slice(-4)}
                </a>
              )}
              <span className="text-white text-[12px] sm:text-[16px]">
                {t(messages[currentMessage])}
              </span>
            </div>
            <div className="bg-loader-border h-[2px] w-full" />
          </div>
        )}
      </div>
    </div>
  );
  if (selector) {
    const portalRoot = document.querySelector(selector);
    if (portalRoot) {
      return ReactDOM.createPortal(content, portalRoot);
    }
  }

  return content;
};

export default GuysLoader;
