import React from 'react';
import MobileConnectPage from 'modules/ConnectPage/page/MobileConnectPage';
import DesktopConnectPage from 'modules/ConnectPage/page/DesktopConnectPage';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useActiveGameRedirect } from 'modules/Room/Hooks/useActiveGameRedirect';

const ConnectPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  useActiveGameRedirect();
  return isMobile ? <MobileConnectPage /> : <DesktopConnectPage />;
};

export default ConnectPage;
