import React, { useEffect, useState, useMemo } from 'react';
import PrimaryButton from 'shared/components/ui/PrimaryButton/PrimaryButton';
import { useDebounce } from 'usehooks-ts';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { useAllowance } from 'modules/Room/Hooks/useAllowance';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useAccount } from 'wagmi';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';
// import ReactGA from 'react-ga4';
import { useRoomStore } from 'shared/store/roomStore';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { useTranslation } from 'react-i18next';
import Approve from 'modules/Room/features/Allowance/Approve';
import { useParams } from 'react-router-dom';
import QuestionIcon from 'assets/question.svg?react';
import './Allowance.scss';
import { CHAINS_IDS } from 'shared/config';

// import ReactPixel from 'react-facebook-pixel';

interface Props {
  preferredBet: number | string;
  betIsValid: boolean;
  setAllowancePending?: (arg0: boolean) => void;
  setAllowanceFetching?: (arg0: boolean) => void;
  balance: number | null;
  joinGameModal?: boolean;
  setStartStep?: (arg0: boolean) => void;
  mobile?: boolean;
  fullAllowance?: boolean;
}

let fullNumber =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const spenderAddress = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;
const Allowance = ({
  preferredBet,
  betIsValid,
  setAllowancePending,
  setAllowanceFetching,
  balance,
  joinGameModal,
  setStartStep,
  mobile,
  fullAllowance,
}: Props) => {
  const { t } = useTranslation();
  const [showTip, setShowTip] = useState(false);
  const [approve, setApprove] = useState(false);
  const [approveState, setApproveState] = useState({
    reject: false,
    error: false,
  });
  const { selectedCurrency } = useCurrenciesStore();
  const {
    gameBetModalState,
    closeAllModals,
    setModalState,
    setNotEnoughModalContinue,
    setNotEnoughModalZeroBalance,
  } = useModalStore();
  const { activeRoomId } = useParams();
  const { setJoinRoomId } = useRoomStore();
  const debouncedBet = useDebounce<number>(+preferredBet, 400);
  const { address, chainId } = useAccount();
  const { setBet, setIsGameSearching, refetchLastRoomId } = useRoomStore();
  const debouncedBetInTokenAmount =
    debouncedBet * 10 ** selectedCurrency!.decimals;
  const {
    data: allowance,
    refetch,
    isFetching,
  } = useAllowance({
    tokenAddress: selectedCurrency!.address,
    spenderAddress,
    ownerAddress: address || NULL_ADDRESS,
  });
  useEffect(() => {
    setAllowanceFetching && setAllowanceFetching(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (allowance && joinGameModal && setStartStep) {
      setStartStep(Number(allowance) >= debouncedBetInTokenAmount);
    }
  }, [allowance, joinGameModal, setStartStep]);
  useEffect(() => {
    refetch();
  }, []);
  const handleClickApprove = () => {
    setAllowancePending && setAllowancePending(true);
    setApprove(true);
  };
  const finishApproveHandler = (approveState: {
    reject: boolean;
    error: boolean;
  }) => {
    setApprove(false);
    setApproveState(approveState);
    setAllowancePending && setAllowancePending(false);
    refetch();
  };
  const startGameHandler = async () => {
    if (balance && balance < +preferredBet) {
      closeAllModals();
      setModalState(ModalTypeEnum.NOT_ENOUGH_COINS, true);
      setNotEnoughModalContinue(false);
      setNotEnoughModalZeroBalance(balance === 0);
      return;
    } else {
      // const startWithZeroX = address?.startsWith('0x');
      // const walletWithoutPrefix = startWithZeroX
      //   ? address?.substring(2)
      //   : address;
      // const eventOptions = {
      //   category: 'game',
      //   action: 'Game Started',
      //   wallet: walletWithoutPrefix,
      //   startWithZeroX: startWithZeroX,
      //   chainId: chainId,
      //   bet: preferredBet,
      // };
      //
      // ReactGA.event('start', eventOptions);
      // ReactPixel.track('StartTrial');

      // if (window.twq) {
      //   window.twq('event', 'tw-op1jf-op1jr', {
      //     conversion_id: null
      //   });
      // }
      setBet(+preferredBet);
      refetchLastRoomId();
      setIsGameSearching(true);
      setJoinRoomId(activeRoomId ? BigInt(activeRoomId) : null);
      setModalState(
        joinGameModal ? ModalTypeEnum.JOIN_GAME : ModalTypeEnum.SET_BET,
        false
      );
      if (joinGameModal) setModalState(ModalTypeEnum.CONNECT_TO_PLAYER, true);
    }
  };
  const isMobileDevice = useMemo(
    () => /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    []
  );
  return (
    <>
      <AnimatedTransition show={approveState.reject && !mobile}>
        <>
          <p className="text-white font-bold text-[12px] sm:text-[16px] mb-[10px]">
            {t(`modals.setBet.reject`)}
          </p>
          <p className="text-white  text-[12px] sm:text-[16px] mb-[20px]">
            {t(`modals.setBet.declinedConnect`)}
          </p>
        </>
      </AnimatedTransition>
      <AnimatedTransition show={approveState.error}>
        <>
          <p className="text-white font-bold text-[12px] sm:text-[16px] mb-[10px]">
            {t(`modals.setBet.unsuccessful`)}
          </p>
          <p className="text-white  text-[12px] sm:text-[16px] mb-[20px]">
            {t(`modals.setBet.oops`)}
          </p>
        </>
      </AnimatedTransition>
      <AnimatedTransition
        show={!approveState.error && !approveState.reject && !mobile}
      >
        <p className="text-white text-[12px] sm:text-[16px] mb-[20px]">
          {t('modals.setBet.authorize')}
        </p>
      </AnimatedTransition>
      <AnimatedTransition show={showTip}>
        <div
          className={`absolute bottom-[73px] sm:bottom-[90px] w-[90%] box-border `}
        >
          <div className="bg-blue-border relative rounded-[8px] tip px-[16px] sm:py-[12px] py-[5px] z-50">
            <p className="text-white text-[12px] sm:text-[16px]">
              {t('modals.setBet.firstConnect')}
            </p>
          </div>
        </div>
      </AnimatedTransition>
      <AnimatedTransition show={gameBetModalState.reject && !mobile}>
        <>
          <p className="text-white font-bold text-[12px] sm:text-[16px] mb-[10px]">
            {t(`modals.setBet.reject`)}
          </p>
          <p className="text-white  text-[12px] sm:text-[16px] mb-[20px]">
            {t(`modals.setBet.declinedConnect`)}
          </p>
        </>
      </AnimatedTransition>
      <AnimatedTransition show={gameBetModalState.error && !mobile}>
        <>
          <p className="text-white font-bold text-[12px] sm:text-[16px] mb-[10px]">
            {t(`modals.setBet.unsuccessful`)}
          </p>
          <p className="text-white  text-[12px] sm:text-[16px] mb-[20px]">
            {t(`modals.setBet.oops`)}
          </p>
        </>
      </AnimatedTransition>
      <div className="flex flex-wrap w-full px-4 gap-[10px]">
        {mobile ? (
          <button
            className={
              !(
                !isFetching &&
                Number(allowance) < debouncedBetInTokenAmount &&
                betIsValid
              )
                ? 'flex-grow text-white px-6 py-3 rounded-[12px] text-4 font-bold border opacity-50'
                : 'flex-grow border border-[#FFE081]  bg-create-btn text-white font-bold px-6 py-3 rounded-[12px] text-4 '
            }
            onClick={handleClickApprove}
            disabled={
              !(
                !isFetching &&
                Number(allowance) < debouncedBetInTokenAmount &&
                betIsValid
              )
            }
          >
            {t('common.authorize')}
          </button>
        ) : (
          <PrimaryButton
            className={'self-center flex-grow'}
            text={'common.authorize'}
            onClick={handleClickApprove}
            disabled={
              !(
                !isFetching &&
                Number(allowance) < debouncedBetInTokenAmount &&
                betIsValid
              )
            }
          />
        )}
        {mobile ? (
          <button
            className={
              !(
                Number(allowance) >= debouncedBetInTokenAmount &&
                debouncedBetInTokenAmount > 0 &&
                betIsValid
              )
                ? 'flex-grow text-white px-6 py-3 rounded-[12px] text-4 font-bold border opacity-50'
                : 'flex-grow border border-[#FFE081]  bg-create-btn text-white font-bold px-6 py-3 rounded-[12px] text-4 '
            }
            disabled={
              !(
                Number(allowance) >= debouncedBetInTokenAmount &&
                debouncedBetInTokenAmount > 0 &&
                betIsValid &&  CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453)
              )
            }
            onClick={startGameHandler}
          >
            {t('common.startGame')}
          </button>
        ) : (
          <PrimaryButton
            text={'common.startGame'}
            className={'self-center flex-grow'}
            disabled={
              !(
                Number(allowance) >= debouncedBetInTokenAmount &&
                debouncedBetInTokenAmount > 0 &&
                betIsValid && CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453)
              )
            }
            onClick={startGameHandler}
          />
        )}
        {!joinGameModal && !mobile && (
          <QuestionIcon
            className={`cursor-pointer`}
            onMouseEnter={!isMobileDevice ? () => setShowTip(true) : undefined}
            onClick={() => setShowTip(!showTip)}
            onMouseLeave={!isMobileDevice ? () => setShowTip(false) : undefined}
            data-testid="question-icon"
          />
        )}
      </div>

      {approve && (
        <Approve
          allowance={allowance}
          finishApproveHandler={finishApproveHandler}
          debouncedBetInTokenAmount={
            fullAllowance ? fullNumber : debouncedBetInTokenAmount
          }
        />
      )}
    </>
  );
};
export default Allowance;
