import { useEffect, useState } from "react";
import winIcon from 'assets/png/win-icon.png';
import loseIcon from 'assets/png/lose-icon.png';

interface MoveCardProps {
  order: number;
  isActive?: boolean;
  activeIcon: string;
  inactiveIcon?: string;
  position: 'left' | 'right';
  title: string;
  isAnimating?: boolean;
  isWinner?: boolean;
  isLooser?: boolean;
  isRoundFinished?: boolean;
  roundDraw?: boolean;
}

const activeItemStyles = 'transition-all ease-in-out delay-600 w-[50px] sm:w-[100px] h-[80px] sm:h-[160px] absolute rounded-[9px] sm:rounded-[18px] border-[#75C5FF] border-[2px] bg-[#172178] text-[10px] sm:text-[20px] font-semibold text-white z-[100]'
const inactiveItemStyles = 'transition-all ease-in-out delay-600 w-[50px] sm:w-[100px] h-[80px] sm:h-[160px] absolute rounded-[9px] sm:rounded-[18px] border-[#36336E] border-[2px] bg-[#1D213E] text-[10px] sm:text-[20px] text-white/50 z-[80]'
const winnerItemStyles = 'bg-gradient-to-b from-[#0D1450] to-[#FFC000] border-[#FFA700] w-[50px] sm:w-[100px] h-[80px] sm:h-[160px] absolute rounded-[9px] sm:rounded-[18px] border-[2px] text-[10px] sm:text-[20px] font-semibold text-white z-[100]';
const looserItemStyles = 'bg-gradient-to-b from-[#0D1450] to-[#7B3656] border-[#E81C34] w-[50px] sm:w-[100px] h-[80px] sm:h-[160px] absolute rounded-[9px] sm:rounded-[18px] border-[2px] text-[10px] sm:text-[20px] font-semibold text-white z-[100]';

const wrapperStyles = 'flex justify-center items-center h-[60px] sm:h-[124px]'

const winnerWrapperStyles = 'bg-gradient-to-b from-[#FF8B00] to-[#FFC000] transition-all ease-in-out delay-600 flex justify-center items-center border-t-[#FFBB3C] border-t-[2px] rounded-b-[9px] sm:rounded-b-[18px]'
const looserWrapperStyles = 'bg-gradient-to-b from-[#7B3656] to-[#E81C34] transition-all ease-in-out delay-600 flex justify-center items-center border-t-[#ED5264] border-t-[2px] rounded-b-[9px] sm:rounded-b-[18px]'
const activeWrapperStyles = 'bg-gradient-to-b from-[#282378] to-[#0C093D] transition-all ease-in-out delay-600 flex justify-center items-center border-t-[#75C5FF] border-t-[2px] rounded-b-[9px] sm:rounded-b-[18px]'
const inactiveWrapperStyles = 'bg-gradient-to-b from-[#373470] to-[#1C1A42] transition-all ease-in-out delay-600 flex justify-center items-center border-t-[#36336E] border-t-[2px] rounded-b-[9px] sm:rounded-b-[18px]'

export const MoveCard = ({
  position,
  order,
  isActive,
  activeIcon,
  inactiveIcon,
  isAnimating,
  title,
  isWinner,
  isLooser,
  isRoundFinished,
  roundDraw,
}: MoveCardProps) => {
  const [currentIcon, setCurrentIcon] = useState<string | undefined>(inactiveIcon);  

  useEffect(() => {
    setTimeout(() => {
      if (isAnimating) {
        setCurrentIcon(inactiveIcon);
      }
    }, 50)
  }, [isAnimating])

  useEffect(() => {
    if (isWinner || roundDraw) {
      setCurrentIcon(activeIcon)
      return;
    }

    setCurrentIcon(inactiveIcon)
  }, [isWinner, roundDraw])

  const getStylesByOrder = () => {
    if (order === 0) {
      return 'top-0 left-0'
    }

    if (order === 1) {
      return `top-[10px] sm:top-[20px] ${position === 'left' ? 'left-[-10px] sm:left-[20px]' : 'left-[10px] sm:left-[-20px]'}`
    }

    if (order === 2) {
      return `top-[20px] sm:top-[40px] ${position === 'left' ? 'left-[-20px] sm:left-[40px]' : 'left-[20px] sm:left-[-40px]'}`
    }
  }

  const getItemStyles = () => {
    if (isWinner && isRoundFinished) {
      return winnerItemStyles;
    }

    if (isLooser && isRoundFinished) {
      return looserItemStyles;
    }

    if (isActive || roundDraw) {
      return activeItemStyles;
    }

    return inactiveItemStyles;
  }

  const getWrapperStyles = () => {
    if (isWinner && isRoundFinished) {
      return winnerWrapperStyles;
    }

    if (isLooser && isRoundFinished) {
      return looserWrapperStyles;
    }

    if ((isAnimating && isActive) || roundDraw) {
      return activeWrapperStyles;
    }

    return inactiveWrapperStyles;
  }

  return (
    <div className={`${getStylesByOrder()} ${getItemStyles()}`}>
      <div className={wrapperStyles}>
        {isWinner && isRoundFinished && <img className="w-[19px] sm:w-[38px] absolute top-0 right-0" src={winIcon} alt="win" />}
        {isLooser && isRoundFinished && <img className="w-[19px] sm:w-[38px] absolute top-0 right-0" src={loseIcon} alt="lose" />}
        <img className="w-[35.5px] sm:w-[71px]" src={currentIcon} alt={title} />
      </div>
      <div className={getWrapperStyles()}>
        {title}
      </div>
    </div>
  )
}
