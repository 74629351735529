import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { useTranslation } from 'react-i18next';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';

export const useRevealModalText = (
  showTimer: boolean,
  showCloseButton: boolean,
  technicalDefeatError: boolean
) => {
  const { revealModalState } = useModalStore();
  const { t } = useTranslation();

  const Reveal = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.choices')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.confirm')}
      </p>
    </>
  );

  const RevealWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.choices')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.timerConfirm')}
      </p>
    </>
  );

  const ErrorText = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.error')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.again')}
      </p>
    </>
  );

  const ErrorTextWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.error')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.revealAgain')}
      </p>
    </>
  );

  const RejectText = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.reject')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.decline')}
      </p>
    </>
  );

  const RejectTextWithTimer = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.reject')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.timerTip')}
      </p>
    </>
  );

  const TechnicalDefeatAllStates = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.gameOver')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.technicalDefeat')}
      </p>
    </>
  );

  const TechnicalDefeatAllStatesError = () => (
    <>
      <p className="text-white text-[12px] sm:text-[16px] mb-[10px] text-justify">
        {t('modals.waitYourReveal.technicalDefeatError')}
      </p>
      <p className="text-white text-[12px] sm:text-[16px] text-justify">
        {t('modals.waitYourReveal.technicalDefeatErrorExplanation')}
      </p>
    </>
  );

  const getTextComponent = () => {
    return (
      <>
        <AnimatedTransition
          show={
            !revealModalState.error &&
            !revealModalState.reject &&
            !showCloseButton &&
            showTimer
          }
        >
          <RevealWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={
            !revealModalState.error &&
            !revealModalState.reject &&
            !showCloseButton &&
            !showTimer
          }
        >
          <Reveal />
        </AnimatedTransition>
        <AnimatedTransition
          show={revealModalState.error && !showCloseButton && showTimer}
        >
          <ErrorTextWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={revealModalState.error && !showCloseButton && !showTimer}
        >
          <ErrorText />
        </AnimatedTransition>
        <AnimatedTransition
          show={revealModalState.reject && !showCloseButton && showTimer}
        >
          <RejectTextWithTimer />
        </AnimatedTransition>
        <AnimatedTransition
          show={revealModalState.reject && !showCloseButton && !showTimer}
        >
          <RejectText />
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton && technicalDefeatError}>
          <TechnicalDefeatAllStatesError />
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton && !technicalDefeatError}>
          <TechnicalDefeatAllStates />
        </AnimatedTransition>
      </>
    );
  };
  return { getTextComponent };
};
