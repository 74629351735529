import React, { useEffect } from 'react';
import { ModalTypeEnum } from '../../constants/modalEnums';
import WaitYourRevealModal from './modals/WaitYourRevealModal/WaitYourRevealModal';
import WaitOpponentMoveModal from './modals/WaitOpponentMoveModal/WaitOpponentMoveModal';
import WaitOpponentRevealModal from './modals/WaitOpponentRevealModal/WaitOpponentRevealModal';
import WaitYourMoveModal from './modals/WaitYourMoveModal/WaitYourMoveModal';
import WaitYouApproveMoveModal from './modals/WaitYouApproveMoveModal/WaitYouApproveMoveModal';
import WatchGameModal from './modals/WatchGame/WatchGame';
import { useModalStore } from '../../store/modalStore';
import { useRoomStore } from 'shared/store/roomStore';
import {
  RoundStagesEnum,
  RoomStatusEnum,
  CloserEnum,
  MovesEnum,
} from 'shared/constants/shared-enums';
import AnimatedTransition from 'shared/components/ui/AnimatedTransition/AnimatedTransition';
import { NULL_ADDRESS, NULL_MOVE } from 'shared/constants/constants';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import JoinGameModal from './modals/JoinGameModal/JoinGameModal';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';

const ModalsPlayGame = () => {
  const { modalState, setModalState, closeAllModals } = useModalStore();
  const navigate = useNavigate();
  const {
    playerA,
    playerB,
    roundStage,
    roomStatus,
    closerRoom,
    roundMoves,
    amIPlayerA,
    winnerStatus,
    someoneElseGame,
    showHash
  } = useRoomStore();
  const { activeRoomId, networkId } = useParams();
  const { address } = useAccount();
  useEffect(() => {
    if (
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address !== NULL_ADDRESS &&
      playerB.address !== address?.toLowerCase()
    ) {
      console.log('watch game');
      navigate(`${ROUTES.STREAM}/${networkId}/${activeRoomId}`)
      closeAllModals();
      setModalState(ModalTypeEnum.WATCH_GAME, true);
    } else if (
      activeRoomId &&
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address === NULL_ADDRESS &&
      !modalState[ModalTypeEnum.CONNECTING_TO_PLAYER] &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      console.log('join game');
      closeAllModals();
      setModalState(ModalTypeEnum.JOIN_GAME, true);
    } else if (
      (roomStatus === RoomStatusEnum.ReadyForGame ||
        roomStatus === RoomStatusEnum.ReadyForReveal) &&
      activeRoomId
    ) {
      if (
        (roundStage === RoundStagesEnum.None ||
          roundStage === RoundStagesEnum.InitGame) &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('start game');
        if (
          !modalState[ModalTypeEnum.WAIT_YOUR_MOVE] &&
          !modalState[ModalTypeEnum.WAIT_YOUR_APPROVE]
        ) {
          closeAllModals();
          setModalState(ModalTypeEnum.WAIT_YOUR_MOVE, true);
        }
      } else if (
        roundStage === RoundStagesEnum.AddMove &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('one player made a move');
        if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.encrMove === NULL_MOVE) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.encrMove === NULL_MOVE)
        ) {
          closeAllModals();
          setModalState(ModalTypeEnum.WAIT_OPPONENT_MOVE, true);
        } else if (
          ((amIPlayerA && roundMoves.playerA.encrMove === NULL_MOVE) ||
            (!amIPlayerA && roundMoves.playerB.encrMove === NULL_MOVE)) &&
          !modalState[ModalTypeEnum.WAIT_YOUR_MOVE] &&
          !modalState[ModalTypeEnum.WAIT_YOUR_APPROVE]
        ) {
          closeAllModals();
          setModalState(ModalTypeEnum.WAIT_YOUR_MOVE, true);
        }
      } else if (
        roundStage === RoundStagesEnum.RevealMove &&
        roomStatus === RoomStatusEnum.ReadyForReveal
      ) {
        console.log('round stage', roundStage);
        console.log('no one make reveal or one make reveal');
        if (
          (roundMoves.playerA.move === MovesEnum.None &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (amIPlayerA && roundMoves.playerA.move === MovesEnum.None) ||
          (!amIPlayerA && roundMoves.playerB.move === MovesEnum.None)
        ) {
          closeAllModals();
          setModalState(ModalTypeEnum.WAIT_YOUR_REVEAL, true);
        } else if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.move === MovesEnum.None &&
            !modalState[ModalTypeEnum.WAIT_OPPONENT_REVEAL])
        ) {
          closeAllModals();
          setModalState(ModalTypeEnum.WAIT_OPPONENT_REVEAL, true);
        }
      }
    } else if (
      winnerStatus &&
      roomStatus === RoomStatusEnum.Closed &&
      activeRoomId &&
      someoneElseGame
    ) {
      closeAllModals();
      setModalState(ModalTypeEnum.WATCH_GAME, true);
    }
  }, [
    playerA,
    playerB,
    address,
    roomStatus,
    closerRoom,
    roundStage,
    roundMoves,
    amIPlayerA,
    activeRoomId,
    winnerStatus,
    someoneElseGame,
  ]);
  // TODO remove from here after modals refactoring
  const { showSignModal } = useShowSignModal();

  return (
    <>
      <AnimatedTransition show={modalState[ModalTypeEnum.JOIN_GAME]}>
        <JoinGameModal open={modalState[ModalTypeEnum.JOIN_GAME]} />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WATCH_GAME] && !showSignModal}
      >
        <WatchGameModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WAIT_YOUR_MOVE] && !showSignModal && !showHash}
      >
        <WaitYourMoveModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WAIT_YOUR_APPROVE] && !showSignModal && !showHash}
      >
        <WaitYouApproveMoveModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WAIT_OPPONENT_MOVE] && !showSignModal && !showHash}
      >
        <WaitOpponentMoveModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WAIT_YOUR_REVEAL] && !showSignModal && !showHash}
      >
        <WaitYourRevealModal />
      </AnimatedTransition>
      <AnimatedTransition
        show={modalState[ModalTypeEnum.WAIT_OPPONENT_REVEAL] && !showSignModal && !showHash}
      >
        <WaitOpponentRevealModal />
      </AnimatedTransition>
    </>
  );
};

export default ModalsPlayGame;
