import React from 'react';
import floor from 'assets/png/floor.png';
import humanLeft from 'assets/png/explor-human-left.png';
import humanRight from 'assets/png/explor-human-right.png';
import classNames from 'classnames';
import { coinIcons, ROUTES } from 'shared/constants';
import { Avatar } from 'shared/components/ui/Avatar/Avatar';
import { RoomData } from 'modules/explore-games/store/store';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

/** Utility to shorten an address. */
const formatAddress = (address: string) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

/** Props for subcomponent that renders a single player's info. */
interface PlayerInfoProps {
  playerAddress: `0x${string}`;
  wins: number;
  align: 'left' | 'right';
}

/**
 * Renders a player's avatar, win count, and shortened address.
 */
const PlayerInfo: React.FC<PlayerInfoProps> = ({
  playerAddress,
  wins,
  align,
}) => {
  const containerClasses = classNames(
    'flex mb-1 lg:mb-2 box-border items-center',
    'bg-[rgba(13,20,80,0.25)] border-[1px] lg:border-[2px] border-[rgba(255,255,255,0.25)]',
    'h-[32px] w-[58px] lg:w-[116px] lg:h-[64px] backdrop-blur-[32px]',
    'rounded-[8px] lg:rounded-[16px] relative' // relative for positioning the avatar
  );

  // Position the avatar on the left or right based on `align`.
  const avatarClasses = classNames(
    'w-[32px] h-[32px] lg:w-[64px] lg:h-[64px]',
    'border-[1px] lg:border-[2px] border-[#FFC000]',
    'rounded-[8px] lg:rounded-[16px] absolute',
    align === 'left' ? 'left-[-2px] top-[-2px]' : 'right-[-2px] top-[-2px]'
  );

  // The numeric win label is offset depending on alignment.
  const winsClasses = classNames(
    'text-[16px] lg:text-[32px] text-center',
    align === 'left' ? 'ml-[36px] lg:ml-[74px]' : 'ml-[8px] lg:ml-[16px]'
  );

  return (
    <div className="flex flex-col items-center">
      <div className={containerClasses}>
        {align === 'left' && (
          <>
            <Avatar userAddress={playerAddress} className={avatarClasses} />
            <span className={winsClasses}>{wins}</span>
          </>
        )}
        {align === 'right' && (
          <>
            <span className={winsClasses}>{wins}</span>
            <Avatar userAddress={playerAddress} className={avatarClasses} />
          </>
        )}
      </div>
      <div className="bg-dark-background leading-none border border-border rounded-[7px] text-[8px] lg:text-[16px] p-1 lg:p-2">
        <span>{formatAddress(playerAddress)}</span>
      </div>
    </div>
  );
};

interface ExploreCardProps {
  room: RoomData;
}

export const ExploreCard: React.FC<ExploreCardProps> = ({ room }) => {
  const availableCurrencies = useCurrenciesStore(
    (state) => state.availableCurrencies
  );

  const { data: roomData, winsCounter, roundCounter, status } = room;

  // Find currency
  const currencyObject = availableCurrencies.find(
    (c) => c.address.toLowerCase() === roomData.token.toLowerCase()
  );

  // // Fallback if currency or icon doesn’t exist
  // if (!currencyObject || !coinIcons[currencyObject.symbol]) {
  //   return (
  //     <div className="w-[288px] h-[154px] lg:w-[576px] lg:h-[310px] flex justify-center items-center p-4 lg:p-8">
  //       Currency not found
  //     </div>
  //   );
  // }

  const IconComponent = coinIcons[currencyObject?.symbol];
  // BET FORMATING
  // const betString = rawBet.toString();
  // const [, decimals] = betString.split('.');
  // let formattedBet = '';
  // const leadingZeros = decimals?.match(/^0+/)?.[0]?.length || 0;
  //
  // if (leadingZeros >= 3) {
  //   formattedBet = `0.0<span class="text-[16px]">${leadingZeros}</span>${
  //     decimals.slice(leadingZeros)[0]
  //   }`;
  // } else {
  //   formattedBet = rawBet.toString();
  // }
  // if (rawBet >= 1_000_000) {
  //   formattedBet = `${(rawBet / 1_000_000).toFixed(1)}M`;
  // } else if (rawBet >= 1_000) {
  //   formattedBet = `${(rawBet / 1_000).toFixed(1)}K`;
  // }
  // Precompute the bet value once.
  const betValue = currencyObject?.decimals
    ? Number(roomData.bet) / 10 ** currencyObject?.decimals
    : '';
  const navigate = useNavigate();
  const { chainId } = useAccount();
  return (
    <div
      onClick={() =>
        navigate(`${ROUTES.STREAM}/${chainId}/${Number(roomData.roomId)}`)
      }
      className="
      {/*w-[288px] lg:w-[576px]*/}
       w-full
       h-[154px]  lg:h-[310px] flex flex-col items-center p-4 lg:p-8 relative overflow-hidden rounded-[16px] lg:rounded-[32px] bg-explore-gradient border-[1px] lg:border-[2px] border-border text-white cursor-pointer"
    >
      {/* Background images */}
      <img
        src={floor}
        alt="floor"
        className="absolute left-[calc(50%-1000px/2)] top-[calc(50%-99px/2+46px)] lg:top-[calc(50%-199px/2+92px)] z-0 h-[200px] min-w-[1000px]"
      />
      <img
        src={humanLeft}
        alt="human-left"
        className={classNames('absolute left-0 top-0 bottom-0 z-1 h-full')}
      />
      <img
        src={humanRight}
        alt="human-right"
        className={classNames('absolute right-0 top-0 bottom-0 z-1 h-full')}
      />

      {/* Bet info */}
      <div className="flex items-center mb-3 lg:mb-8 text-2xl lg:text-4xl leading-none">
        <span className="font-black mr-2">{betValue}</span>
        <div className="rounded-full w-1 h-1 lg:w-2 lg:h-2 bg-white opacity-50 mr-2" />
        {IconComponent && (
          <IconComponent className="w-[18px] h-[18px] lg:w-9 lg:h-9 mr-1" />
        )}
        <span className="font-bold">{currencyObject?.symbol}</span>
      </div>

      {/* Players */}
      <div className="flex items-center z-50 gap-1 lg:gap-2">
        <PlayerInfo
          playerAddress={roomData.playerA}
          wins={Number(winsCounter.winsPlayerA)}
          align="left"
        />
        <span className="font-bold text-[24px] lg:text-[48px]">VS</span>
        <PlayerInfo
          playerAddress={roomData.playerB}
          wins={Number(winsCounter.winsPlayerB)}
          align="right"
        />
      </div>

      {/* Footer */}
      <div className="absolute bottom-0 left-0 right-0 bg-explore-footer-gradient-grape p-2.5 lg:p-5 flex justify-between items-center">
        <div className="flex items-center font-bold text-[14px] lg:text-[28px] leading-none gap-1 lg:gap-2">
          {status !== RoomStatusEnum.Closed && (
            <>
              <div className="w-2 h-2 lg:w-[10px] lg:h-[10px] rounded-full bg-[#0AF33D] animate-pulse" />
              <p>Live</p>
              <div className="rounded-full w-[2px] h-[2px] lg:w-1 lg:h-1 bg-white opacity-50" />
            </>
          )}
          <p>Round {roundCounter}</p>
        </div>
        <div className="flex items-center font-medium text-[10px] lg:text-[20px] gap-1 lg:gap-2 leading-none">
          <span>ID:</span>
          <span>{Number(roomData.roomId)}</span>
          {/*<div className="rounded-full w-[2px] h-[2px] lg:w-1 lg:h-1 bg-white opacity-50" />*/}
          {/*<span className="opacity-75">3 min ago</span>*/}
          {/*  i think we have time ago*/}
        </div>
      </div>
    </div>
  );
};
