import React from 'react';
import waitingMan from 'assets/png/waitingMan.png';
import LoaderHoc from 'shared/components/ui/Loaders/LoaderHoc/LoaderHoc';

interface Props {
  messages: string[];
  className?: string;
}

const WaitingMan = ({ messages, className }: Props) => {
  return (
    <LoaderHoc messages={messages} className={className}>
      <img
        src={waitingMan}
        alt="Animated character"
        className="mb-[8px] pr-[60px]"
      />
    </LoaderHoc>
  );
};

export default WaitingMan;
