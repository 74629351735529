import { CreateGameSection, LiveGamesSection } from "../../components"
import { Header } from 'shared/components/sections/header/header';
import { useTranslation } from 'react-i18next';

export const DesktopView = () => {
  const { t } = useTranslation();
  return (
    <div
      className="relative w-full h-full min-h-[100vh] max-w-[1800px] mx-auto justify-end lg:justify-between p-[24px] gap-[32px] flex-col-reverse lg:flex-row">
      <Header
        title={t('pages.lobby')}
      />
      <div
        className="flex justify-end lg:justify-between p-[24px] gap-[32px] flex-col-reverse lg:flex-row">
        <LiveGamesSection />
        <CreateGameSection />
      </div>
    </div>

  )
}
